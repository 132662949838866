import React from "react";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useTranslatedField } from "../TranslationProvider";
import { RadioFormField } from "../../Common/Fields";
import { useField } from "react-final-form";
import { ApplicationType } from "../Elimination/SelectApplicationType";

export interface OrganizationEmployeePromptProps {}

export function OrganizationEmployeePrompt({}: OrganizationEmployeePromptProps) {
  return (
    <>
      <RadioFormField
        name="applicant.organizationLocation"
        label={(t) => t.applicant.isOrganizationInNunavik.question}
        vertical
        options={[
          {
            value: "insideNunavik",
            text: (t) => t.applicant.isOrganizationInNunavik.insideNunavik,
          },
          {
            value: "outsideNunavik",
            text: (t) => t.applicant.isOrganizationInNunavik.outsideNunavik,
          },
        ]}
        showSpace
      />
    </>
  );
}
