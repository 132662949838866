import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { TextFormField } from "../../Common/Fields";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { useTranslatedField } from "../TranslationProvider";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import MailOutlineIcon from "@mui/icons-material/MailOutline";

export interface BankingFieldsProps {}

export function BankingFields({}: BankingFieldsProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const bankNumber = useTranslatedField(
    (t) => t.payment.bankingInfo.bankNumber,
    " / ",
  );
  const branchNumber = useTranslatedField(
    (t) => t.payment.bankingInfo.branchNumber,
    " / ",
  );
  const accountNumber = useTranslatedField(
    (t) => t.payment.bankingInfo.accountNumber,
    " / ",
  );
  const bankName = useTranslatedField(
    (t) => t.payment.bankingInfo.bankName,
    " / ",
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ConvertToMui getField={(t) => t.payment.bankingInfo.prompt} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="bankingInfo.bankNumber"
          label={bankNumber}
          allowedContent="numeric"
          required
          icon={<MailOutlineIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="bankingInfo.branchNumber"
          label={branchNumber}
          allowedContent="numeric"
          required
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="bankingInfo.accountNumber"
          label={accountNumber}
          allowedContent="numeric"
          required
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12}>
        <TextFormField
          name="bankingInfo.bankName"
          label={bankName}
          required
          icon={<AccountBalanceIcon />}
        />
      </Grid>
    </Grid>
  );
}
