import React, { useEffect, useState } from "react";
import parse, {
  DOMNode,
  domToReact,
  Element,
  HTMLReactParserOptions,
} from "html-react-parser";
import { Box, Typography, TypographyProps } from "@mui/material";
import { ApplicationFormSpec } from "../ApplicationForm/applicationFormSpec";
import {
  useAllTranslations,
  useTranslations,
} from "../ApplicationForm/TranslationProvider";
import { appendWithHtml } from "./appendInuktitut";

export interface ConvertToMuiProps {
  getField?: (t: ApplicationFormSpec) => string | undefined;
  raw?: string;
  fadeInOut?: boolean;
}

const options: HTMLReactParserOptions = {
  replace: convertToMUI,
};

function convertToMUI(
  domNode: DOMNode,
): ReturnType<NonNullable<HTMLReactParserOptions["replace"]>> {
  const tagName = (domNode as Element).tagName;

  if (tagName == null) {
    return;
  }

  if (tagName == "ul") {
    return (
      <ul>{domToReact((domNode as Element).children as DOMNode[], options)}</ul>
    );
  }

  if (tagName == "li") {
    return (
      <Typography component="li">
        {domToReact((domNode as Element).children as DOMNode[])}
      </Typography>
    );
  }

  return (
    <Typography
      paragraph={tagName == "p"}
      variant={tagNameToVariant(tagName)}
      fontWeight={tagName == "strong" || tagName == "b" ? "bold" : undefined}
      fontStyle={tagName == "i" ? "italic" : undefined}
    >
      {domToReact((domNode as Element).children as DOMNode[])}
    </Typography>
  );
}

function tagNameToVariant(tag: string): TypographyProps["variant"] {
  if (
    tag == "h1" ||
    tag == "h2" ||
    tag == "h3" ||
    tag == "h4" ||
    tag == "h5" ||
    tag == "h6"
  ) {
    return tag;
  }

  return "body1";
}

export function ConvertToMui({ raw, getField, fadeInOut }: ConvertToMuiProps) {
  const { t, iu } = useAllTranslations();

  const html = getField ? appendWithHtml(t, iu, getField) : raw ?? "";

  const [currentText, setCurrentText] = useState(html);
  const [fadeOut, setFadeOut] = useState(false);

  useEffect(() => {
    if (html == currentText) {
      return;
    }

    setFadeOut(true);

    setTimeout(() => {
      setCurrentText(html);
      setFadeOut(false);
    }, 450);
  }, [html, currentText]);

  if (fadeInOut) {
    return (
      <>
        <Box sx={{ transition: "0.4s", opacity: fadeOut ? 0 : 1 }}>
          {parse(currentText ?? "", {
            replace: convertToMUI,
          })}
        </Box>
      </>
    );
  }

  return (
    <>
      {parse(currentText ?? "", {
        replace: convertToMUI,
      })}
    </>
  );
}
