import React from "react";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { EliminationFormSpec } from "../applicationFormSpec";
import { SelectFormField } from "../../Common/Fields";
import FeedIcon from "@mui/icons-material/Feed";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useTranslations } from "../TranslationProvider";

const applicationTypes = [
  "personal",
  "oneTimeProject",
  "structuringProject",
  "payment",
] as const;
export type ApplicationType = (typeof applicationTypes)[number];

export interface SelectApplicationTypeProps {}

export function SelectApplicationType({}: SelectApplicationTypeProps) {
  const { t, iu } = useTranslations("elimination");

  return (
    <>
      <ConvertToMui getField={(t) => t.elimination.requestType.prompt} />
      <SelectFormField
        name="applicationType"
        label={appendWithoutHtml(t, iu, (t) => t.requestType.label, " / ")}
        options={applicationTypes
          // Removing Structuring Project as an option for now
          .filter((t) => t !== "structuringProject")
          .map((type) => ({
            value: type,
            text: appendWithoutHtml(
              t,
              iu,
              (t) => t.requestType.options[type].name,
              " / ",
            ),
            subText: appendWithoutHtml(
              t,
              iu,
              (t) => t.requestType.options[type].description,
              " / ",
            ),
          }))}
        icon={<FeedIcon fontSize="large" />}
      />
    </>
  );
}
