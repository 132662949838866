import React from "react";
import { ApplicationFormSpec, BasicPromptSpec } from "./applicationFormSpec";
import { ConvertToMui } from "../Common/ConvertToMUI";
import { YesNoFormField } from "../Common/Fields";
import { appendWithoutHtml } from "../Common/appendInuktitut";
import { useTranslatedField } from "./TranslationProvider";

export interface BasicPromptProps {
  getPrompt: (t: ApplicationFormSpec) => BasicPromptSpec;
  fieldName: string;
  allowUnsure?: boolean;
}

export function BasicYesNoPrompt({
  getPrompt,
  fieldName,
  allowUnsure,
}: BasicPromptProps) {
  const yesNoLabel = useTranslatedField((t) => getPrompt(t).question, " \n ");

  return (
    <>
      <ConvertToMui getField={(t) => getPrompt(t).prompt} />
      <YesNoFormField
        label={(t) => getPrompt(t).question}
        name={fieldName}
        showSpace
        allowUnsure={allowUnsure}
      />
    </>
  );
}
