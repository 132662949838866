import { FormNode } from "../Common/FormFlow";
import React from "react";
import { buildEliminationFlow, EliminationKey } from "./Elimination";
import { ApplicationFormSpec } from "./applicationFormSpec";
import { ApplicantKey, buildApplicantFlow } from "./Applicant";
import { CFIFormResult, FormResult, PaymentFormResult } from "./cfiFormResult";
import { buildChildrenFlow, ChildrenKey } from "./Children";
import { buildServiceFlow, ServiceKey } from "./Services";
import { buildConclusionFlow, ConclusionKey } from "./Conclusion";
import { Box, Skeleton, Stack } from "@mui/material";
import { buildProjectFlow, ProjectKey } from "./Project";
import { buildPaymentFlow, PaymentKey } from "./Payment/paymentFlow";

export type CFIApplicationKeys =
  | EliminationKey
  | ApplicantKey
  | ChildrenKey
  | ServiceKey
  | ProjectKey
  | PaymentKey
  | ConclusionKey
  | "TODO";

export function buildApplicationFlow(
  formSpec: ApplicationFormSpec,
  iuFormSpec: ApplicationFormSpec,
): (
  | FormNode<CFIFormResult, CFIApplicationKeys>
  | FormNode<PaymentFormResult, CFIApplicationKeys>
)[] {
  return [
    ...buildEliminationFlow(formSpec.elimination, iuFormSpec.elimination),
    ...buildApplicantFlow(formSpec.applicant, iuFormSpec.applicant),
    ...buildChildrenFlow(formSpec.children, iuFormSpec.children),
    ...buildProjectFlow(formSpec.project, iuFormSpec.project),
    ...buildServiceFlow(formSpec.service, iuFormSpec.service),
    ...buildPaymentFlow(),
    ...buildConclusionFlow(),
    { id: "TODO", element: "TODO" },
  ];
}
