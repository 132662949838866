import React from "react";
import { Box, Button, Stack } from "@mui/material";
import { FileFormField } from "../../Common/Fields/FileFormField";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { useForm } from "react-final-form";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { TextFormField } from "../../Common/Fields";
import CommentIcon from "@mui/icons-material/Comment";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { RelationshipOptions } from "../Applicant/RelationSelector";

export interface DocumentsProps {}

export function Documents({}: DocumentsProps) {
  const { mutators } = useForm();
  const { t, iu } = useTranslations("conclusion");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const commentsPlaceholder = useTranslatedField(
    (t) => t.conclusion.documents.comments.placeholder,
    " / ",
  );

  const applicationType = useFieldValue<ApplicationType>("applicationType");
  const relationship = useFieldValue<RelationshipOptions | "other">(
    "relationship",
  );

  return (
    <Stack spacing={5}>
      <ConvertToMui getField={(t) => t.conclusion.documents.prompt} />
      {applicationType === "personal" &&
        relationship !== "parentOrGuardian" &&
        relationship !== "self" && (
          <FileFormField
            name="documents.parentalAuthorization"
            label={(t) => t.conclusion.documents.parentalAuthorization}
            multiple
          />
        )}
      {applicationType === "personal" && (
        <FileFormField
          name="documents.recommendationAndDiagnoses"
          label={(t) => t.conclusion.documents.recommendationAndDiagnoses}
          multiple
        />
      )}

      {(applicationType === "oneTimeProject" ||
        applicationType === "structuringProject") && (
        <FileFormField
          name="documents.supportLetter"
          label={(t) => t.conclusion.documents.supportLetter}
          multiple
        />
      )}

      <FileFormField
        name="documents.serviceQuotes"
        label={(t) => t.conclusion.documents.serviceQuotes}
        multiple
      />
      <FileFormField
        name="documents.invoices"
        label={(t) => t.conclusion.documents.invoices}
        multiple
      />
      <FileFormField
        name="documents.other"
        label={(t) => t.conclusion.documents.other}
        multiple
      />
      <Box>
        <ConvertToMui
          getField={(t) => t.conclusion.documents.comments.prompt}
        />
        <TextFormField
          name="documentComments"
          placeholder={commentsPlaceholder}
          multiline
          icon={<CommentIcon />}
        />
        <Box textAlign="right" pt={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => mutators.finishedAddingDocuments()}
          >
            {appendWithoutHtml(tCommon, iuCommon, (t) => t.next, " / ")}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}
