import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import {
  useTranslatedField,
  useTranslatedSelectOptions,
} from "../TranslationProvider";
import ShapesIcon from "@mui/icons-material/Interests";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { ServiceFirstTimeRequest } from "./ServiceFirstTimeRequest";
import FlagIcon from "@mui/icons-material/Flag";

function applyNamespace(namespace: string | undefined, name: string) {
  return namespace ? `${namespace}.${name}` : name;
}

export interface FundingReasonProps {
  namespace?: string;
  showFundingReason?: boolean;
  showFirstTimePrompt?: boolean;
  showGoal?: boolean;
}

export function FundingReason({
  namespace,
  showFirstTimePrompt,
  showFundingReason,
  showGoal,
}: FundingReasonProps) {
  const applicationType = useFieldValue<ApplicationType>("applicationType");

  const fundingReasonPrompt = useTranslatedField(
    (t) => t.service.fundingReason.prompt,
    " / ",
  );
  const fundingReasons = useTranslatedSelectOptions(
    (t) => t.service.fundingReason.options,
  );
  const other = useTranslatedField((t) => t.common.other, " / ");
  const pleaseSpecify = useTranslatedField(
    (t) => t.common.pleaseSpecify,
    " / ",
  );

  const requestContext = useTranslatedField(
    (t) =>
      applicationType === "personal"
        ? t.service.individualRequestContext
        : t.service.projectRequestContext,
    " / ",
  );

  const requestGoal = useTranslatedField(
    (t) => t.service.projectRequestGoal,
    " / ",
  );

  const requestNeed = useTranslatedField(
    (t) => t.service.groupRequestNeed,
    " / ",
  );

  const fundingReason = useFieldValue<string>(
    applyNamespace(namespace, "fundingReason"),
  );

  return (
    <>
      {showFundingReason && (
        <>
          <Grid item xs={12}>
            <SelectFormField
              name={applyNamespace(namespace, "fundingReason")}
              label={fundingReasonPrompt}
              options={[
                ...fundingReasons,
                {
                  value: "Other",
                  text: other,
                },
              ]}
              required
              icon={<HelpOutlineIcon />}
            />
          </Grid>
          {fundingReason === "Other" && (
            <Grid item xs={12}>
              <TextFormField
                name={applyNamespace(namespace, "customFundingReason")}
                label={pleaseSpecify}
                required
                showSpace
              />
            </Grid>
          )}
        </>
      )}

      {showGoal && (
        <Grid item xs={12}>
          <TextFormField
            name={applyNamespace(namespace, "requestGoal")}
            label={requestGoal}
            multiline
            required
            maxLength="unlimited"
            icon={<FlagIcon />}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        <TextFormField
          name={applyNamespace(namespace, "requestContext")}
          label={requestContext}
          multiline
          required
          maxLength="unlimited"
          icon={<ShapesIcon />}
        />
      </Grid>
      {applicationType === "personal" && (
        <Grid item xs={12}>
          <TextFormField
            name={applyNamespace(namespace, "requestNeed")}
            label={requestNeed}
            multiline
            icon={<AnnouncementIcon />}
            maxLength="unlimited"
            required
          />
        </Grid>
      )}

      {showFirstTimePrompt && (
        <Grid item xs={12}>
          <ServiceFirstTimeRequest namespace={namespace} />
        </Grid>
      )}
    </>
  );
}
