import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { useFieldValue } from "../../Common/useFieldValue";
import { Payor, payors } from "../cfiFormResult";

export interface PayorFieldsProps {
  name: string;
}

export function PayorFields({ name }: PayorFieldsProps) {
  const payorLabel = useTranslatedField(
    (t) => t.service.whoIsPaying.prompt,
    " / ",
  );

  const { md } = useBreakpoints();
  const pleaseSpecify = useTranslatedField(
    (t) => t.common.pleaseSpecify,
    " / ",
  );
  const pleaseSpecifyServiceProvider = useTranslatedField(
    (t) => t.service.whoIsPaying.specifyServiceProvider,
    " / ",
  );

  const payor = useFieldValue<Payor>(`${name}.payor`);
  const { t, iu } = useTranslations("service");

  const showCustom = payor === "organization" || payor === "serviceProvider";

  return (
    <>
      <Grid item xs={12} md={showCustom ? 3 : 9}>
        <SelectFormField
          name={`${name}.payor`}
          label={payorLabel}
          options={payors.map((p) => ({
            value: p,
            text: appendWithoutHtml(
              t,
              iu,
              (t) => t.whoIsPaying.options[p],
              " / ",
            ),
          }))}
          showSpace={!md}
          required
        />
      </Grid>
      {showCustom && (
        <Grid item xs={12} md={6}>
          <TextFormField
            name={`${name}.customPayor`}
            label={
              payor === "organization"
                ? pleaseSpecify
                : pleaseSpecifyServiceProvider
            }
            showSpace={!md}
            required={payor === "organization"}
          />
        </Grid>
      )}
    </>
  );
}
