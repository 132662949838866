import React, { createContext, useCallback, useContext } from "react";
import { useAsyncData } from "@torqit/crew-2ls";
import { translationApi } from "../Common/API";
import { ApplicationType } from "./Elimination/SelectApplicationType";
import { useSelectedLanguage } from "../SelectedLanguageProvider";
import { ServiceCategory } from "../Common/API/translationApi";
import { appendWithoutHtml } from "../Common/appendInuktitut";
import { useTranslatedField } from "./TranslationProvider";

export interface ServiceOptionsData {
  data: ReturnType<typeof useAsyncData<ServiceCategory[]>>;
  getServiceLabel: (id: string) => string;
}

const ServiceOptionsContext = createContext<ServiceOptionsData | null>(null);

export interface ServiceOptionsProps {
  children: React.ReactNode;
}

export function ServiceOptionsProvider({ children }: ServiceOptionsProps) {
  const data = useAsyncData(() => translationApi.getServices(), []);
  const unknownLabel = useTranslatedField((t) => t.common.unknown, " / ");
  const { language } = useSelectedLanguage();

  const getServiceLabel = useCallback(
    (id: string) => {
      for (const category of data.data ?? []) {
        const option = category.options.find((o) => o.value === id);

        if (option) {
          return `${language == "en" ? option.text.en_CA : option.text.fr_CA} / ${option.text.iu}`;
        }
      }

      return unknownLabel;
    },
    [data.data, unknownLabel, language],
  );

  return (
    <ServiceOptionsContext.Provider value={{ data, getServiceLabel }}>
      {children}
    </ServiceOptionsContext.Provider>
  );
}

export function useServiceOptions(applicationType: ApplicationType) {
  const { language } = useSelectedLanguage();
  const result = useContext(ServiceOptionsContext);
  const otherLabel = useTranslatedField((t) => t.common.other, " / ");

  if (!result) {
    throw new Error(
      "useServiceOptions() cannot be used without being wrapped by a Untitled-1",
    );
  }

  const {
    data: { data, ...everythingElse },
  } = result;

  const categories = data
    ?.filter((d) => d.applicationTypes?.includes(applicationType))
    .map((c) => ({
      group: `${language === "en" ? c.group.en_CA : c.group.fr_CA} / ${c.group.iu}`,
      options: [
        ...c.options.map((o) => ({
          text: `${language === "en" ? o.text.en_CA : o.text.fr_CA} / ${o.text.iu}`,
          value: o.value,
        })),
        {
          value: `Other (${c.group.en_CA})`,
          text: `${otherLabel}`,
          noBold: true,
          subText: `${language === "en" ? c.group.en_CA : c.group.fr_CA} / ${c.group.iu}`,
        },
      ],
    }));

  return { categories, ...everythingElse };
}

export function useGetServiceLabel() {
  const result = useContext(ServiceOptionsContext);

  if (!result) {
    throw new Error(
      "useServiceOptions() cannot be used without being wrapped by a Untitled-1",
    );
  }

  return result.getServiceLabel;
}
