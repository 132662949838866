import React from "react";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { DeepPartial } from "../../Common/common";
import { FormExit } from "./FormExit";
import { FormNode, FormNodeTransition } from "../../Common/FormFlow";
import { EliminationFormSpec } from "../applicationFormSpec";
import { BasicYesNoPrompt } from "../BasicYesNoPrompt";
import { SelectApplicationType } from "./SelectApplicationType";
import { ConfirmHasDocuments } from "./ConfirmHasDocuments";
import { CFIFormResult, FormResult } from "../cfiFormResult";

export type EliminationKey =
  | "hasBeneficiaryNumbers"
  | "EXIT_noBeneficiaryNumber"
  | "belowMajorityAgePrompt"
  | "EXIT_aboveMajorityAge"
  | "exploreFundingOptions"
  | "EXIT_exploreFunding"
  | "selectApplicationType"
  | "confirmHasDocuments"
  | "EXIT_missingDocuments";

function yesNoTransition<R extends FormResult>(
  targetValue: (r: DeepPartial<R>) => string | undefined,
  yesTarget: CFIApplicationKeys,
  noTarget: CFIApplicationKeys,
): FormNodeTransition<R, CFIApplicationKeys>[] {
  return [
    {
      to: noTarget,
      on: (r) => targetValue(r) === "No" || targetValue(r) === "Unsure",
    },
    {
      to: yesTarget,
      on: (r) => targetValue(r) === "Yes",
    },
  ];
}

export function buildEliminationFlow(
  formSpec: EliminationFormSpec,
  iuFormSpec: EliminationFormSpec,
): FormNode<FormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "selectApplicationType",
      element: <SelectApplicationType />,
      transitions: [
        {
          to: "confirmHasDocuments",
          on: (r) => r.applicationType == "payment",
        },
        {
          to: "belowMajorityAgePrompt",
          on: (r) =>
            r.applicationType === "oneTimeProject" ||
            r.applicationType === "structuringProject",
        },
        {
          to: "hasBeneficiaryNumbers",
          on: (r) => r.applicationType != null,
        },
      ],
    },

    {
      id: "hasBeneficiaryNumbers",
      element: (
        <BasicYesNoPrompt
          getPrompt={(t) => t.elimination.formIntro}
          fieldName="hasBeneficiaryNumbers"
        />
      ),
      transitions: yesNoTransition(
        (r) => (isCFIResult(r) ? r.hasBeneficiaryNumbers : undefined),
        "belowMajorityAgePrompt",
        "EXIT_noBeneficiaryNumber",
      ),
    },

    {
      id: "EXIT_noBeneficiaryNumber",
      maxWidth: 540,
      end: true,
      element: <FormExit message="noBeneficiaryNumber" />,
    },

    {
      id: "belowMajorityAgePrompt",
      element: (
        <BasicYesNoPrompt
          getPrompt={(t) => t.elimination.belowMajorityAgePrompt}
          fieldName="underAgeOfMajority"
        />
      ),
      transitions: yesNoTransition(
        (r) => (isCFIResult(r) ? r.underAgeOfMajority : undefined),
        "exploreFundingOptions",
        "EXIT_aboveMajorityAge",
      ),
    },

    {
      id: "EXIT_aboveMajorityAge",
      maxWidth: 540,
      end: true,
      element: <FormExit message="aboveMajorityAge" />,
    },

    {
      id: "exploreFundingOptions",
      element: (
        <BasicYesNoPrompt
          getPrompt={(t) => t.elimination.fundingOptions}
          fieldName="hasExploredFunding"
          allowUnsure
        />
      ),
      transitions: yesNoTransition(
        (r) => (isCFIResult(r) ? r.hasExploredFunding : undefined),
        "confirmHasDocuments",
        "EXIT_exploreFunding",
      ),
    },

    {
      id: "EXIT_exploreFunding",
      maxWidth: 540,
      end: true,
      element: <FormExit message="exploreFunding" />,
    },

    {
      id: "confirmHasDocuments",
      element: <ConfirmHasDocuments formSpec={formSpec} />,
      transitions: [
        {
          to: "paymentType",
          on: (r) =>
            r.applicationType === "payment" && r.hasDocuments === "Yes",
        },
        ...yesNoTransition<FormResult>(
          (r) => r.hasDocuments,
          "requestUrgency",
          "EXIT_missingDocuments",
        ),
      ],
    },

    {
      id: "EXIT_missingDocuments",
      maxWidth: 540,
      end: true,
      element: <FormExit message="missingDocuments" />,
    },
  ];
}

function isCFIResult(
  r: DeepPartial<FormResult>,
): r is DeepPartial<CFIFormResult> {
  return r.applicationType != "payment";
}
