import { Box, Stack, SxProps, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { appendWithoutHtml } from "./appendInuktitut";
import { useTranslations } from "../ApplicationForm/TranslationProvider";
import AddIcon from "@mui/icons-material/Add";
import AddCricleIcon from "@mui/icons-material/AddCircle";
import UploadIcon from "@mui/icons-material/Upload";
import FileIcon from "@mui/icons-material/InsertDriveFile";

export interface FileDropperProps {
  /** The currently dropped files */
  files?: File[];
  /** Event fired when files were either dropped or selected conventionally */
  onChange?: (files: File[]) => void;
  multiple?: boolean;
}

export const FileDropper: React.FC<FileDropperProps> = ({
  files,
  onChange,
  multiple,
}) => {
  const [dragOverDropzone, setDragOverDropzone] = useState(false);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    multiple,
    onDragEnter: () => setDragOverDropzone(true),
    onDragOver: (e) => {
      e.preventDefault();
      e.stopPropagation();
    },
    onDragLeave: () => setDragOverDropzone(false),
    onDrop: () => setDragOverDropzone(false),
    onDropAccepted: (acceptedFiles, event) => {
      onChange && onChange(acceptedFiles);
    },
  });

  const actualFiles = files ?? acceptedFiles;
  const { t, iu } = useTranslations("conclusion");

  return (
    <>
      <Box
        {...getRootProps()}
        sx={(theme) => {
          const draggingStyles: SxProps = dragOverDropzone
            ? {
                backgroundColor: "rgb(219, 219, 236)",
                border: `7px dashed ${theme.palette.secondary.dark}`,
                padding: "14px",
                color: theme.palette.primary.dark,
              }
            : {};

          return {
            padding: "20px",
            borderRadius: 1,
            backgroundColor: "rgba(255, 255, 255, 0.161)",
            border: `1px dashed ${theme.palette.secondary.main}`,
            textAlign: "center",
            fontSize: 24,
            fontWeight: "bold",
            color: theme.palette.primary.main,
            transition: "0.2s",
            cursor: "pointer",
            userSelect: "none",
            verticalAlign: "middle",
            ...draggingStyles,
            "&:hover": {
              backgroundColor: "rgb(223, 222, 231)",
            },
            "&:hover:active": {
              backgroundColor: "rgb(209, 208, 216)",
            },
          };
        }}
      >
        <input {...getInputProps()} />
        <Stack gap={1} sx={{ pointerEvents: "none" }}>
          {actualFiles.length > 0 && (
            <Box>
              <FileIcon /> {actualFiles.map((f) => f.name).join(", ")}
            </Box>
          )}
          {!dragOverDropzone && (
            <Box>
              <AddIcon />{" "}
              {appendWithoutHtml(t, iu, (t) => t.documents.uploadFiles, " / ")}
            </Box>
          )}
          {dragOverDropzone && (
            <Box>
              <AddCricleIcon />{" "}
              {appendWithoutHtml(t, iu, (t) => t.documents.uploadFiles, " / ")}
            </Box>
          )}
        </Stack>
      </Box>
    </>
  );
};
