import { Stack } from "@mui/material";
import React from "react";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { TextFormField, YesNoFormField } from "../../Common/Fields";
import { useTranslatedField } from "../TranslationProvider";
import EmailIcon from "@mui/icons-material/Email";

export interface EmailAndVoidChequeProps {}

export function EmailAndVoidCheque({}: EmailAndVoidChequeProps) {
  const emailLabel = useTranslatedField(
    (t) => t.applicant.applicantInfo.email,
    " / ",
  );

  return (
    <Stack gap={1}>
      <ConvertToMui getField={(t) => t.payment.paymentEmailAddress} />
      <TextFormField
        name="paymentNotificationEmail"
        label={emailLabel}
        required
        icon={<EmailIcon />}
      />
      <YesNoFormField
        name="hasVoidCheque"
        label={(t) => t.payment.hasVoidChequePrompt}
        showSpace
      />
    </Stack>
  );
}
