import React from "react";
import { Box, Collapse, Grid } from "@mui/material";
import { CheckboxFormField } from "../../Common/Fields";
import { useFieldValue } from "../../Common/useFieldValue";
import { AddressFields } from "../../Common/AddressFields";
import { useTranslatedField } from "../TranslationProvider";
import { RelationshipOptions } from "../Applicant/RelationSelector";

export interface SharedChildrenAddressProps {}

export function SharedChildrenAddress({}: SharedChildrenAddressProps) {
  const sameAddressAsApplicant = useFieldValue<boolean>(
    "childrenAddress.sameAddressAsApplicant",
  );
  const sameAddressAsApplicantLabel = useTranslatedField(
    (t) => t.children.sameAddressAsApplicant,
    " / ",
  );

  const relation = useFieldValue<RelationshipOptions>("relationship");

  const canBeSameAsApplicant =
    relation === "parentOrGuardian" ||
    relation === "self" ||
    relation === "fosterParent" ||
    relation === "familyMember";

  return (
    <Grid container spacing={1}>
      {canBeSameAsApplicant && (
        <Grid item xs={12}>
          <Box pt={1}>
            <CheckboxFormField
              name="childrenAddress.sameAddressAsApplicant"
              label={sameAddressAsApplicantLabel}
              showSpace
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={12}>
        <Collapse in={!sameAddressAsApplicant} unmountOnExit>
          <Grid container spacing={1}>
            <AddressFields namespace="childrenAddress" />
          </Grid>
        </Collapse>
      </Grid>
    </Grid>
  );
}
