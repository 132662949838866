import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "./Fields";
import BusinessIcon from "@mui/icons-material/Business";
import {
  useTranslatedField,
  useTranslatedSelectOptions,
} from "../ApplicationForm/TranslationProvider";
import { useBreakpoints } from "./useBreakpoints";

export interface AddressFieldsProps {
  namespace: string;
}

export function AddressFields({ namespace }: AddressFieldsProps) {
  const mailingAddress = useTranslatedField(
    (t) => t.applicant.applicantInfo.mailingAddress,
    " / ",
  );
  const community = useTranslatedField(
    (t) => t.applicant.applicantInfo.community,
    " / ",
  );
  const provinceTerritory = useTranslatedField(
    (t) => t.applicant.applicantInfo.provinceTerritory,
    " / ",
  );
  const postalCode = useTranslatedField(
    (t) => t.applicant.applicantInfo.postalCode,
    " / ",
  );

  const provincesAndTerritories = useTranslatedSelectOptions(
    (t) => t.applicant.applicantInfo.provincesAndTerritories,
  );

  const { sm } = useBreakpoints();

  return (
    <>
      <Grid item xs={12}>
        <TextFormField
          name={`${namespace}.mailingAddress`}
          label={mailingAddress}
          required
          icon={<BusinessIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name={`${namespace}.community`}
          allowedContent="alpha"
          label={community}
          required
          showSpace
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectFormField
          name={`${namespace}.provinceTerritory`}
          label={provinceTerritory}
          required
          options={provincesAndTerritories}
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name={`${namespace}.postalCode`}
          allowedContent="alphanumeric"
          maxLength={6}
          label={postalCode}
          required
          showSpace={!sm}
        />
      </Grid>
    </>
  );
}
