import React from "react";
import { CFIFormResult, CFIService } from "../cfiFormResult";
import { FormSpy } from "react-final-form";
import { DeepPartial } from "../../Common/common";

export interface ServiceFormSpyProps {
  index: number;
  render: (
    service: DeepPartial<CFIService>,
    state: DeepPartial<CFIFormResult>,
  ) => React.ReactNode;
}

export function ServiceFormSpy({ index, render }: ServiceFormSpyProps) {
  return (
    <FormSpy<CFIFormResult>
      subscription={{ values: true }}
      render={({ values }) => render(values.services[index], values)}
    />
  );
}
