import React from "react";
import { Box, Button, Divider, Stack } from "@mui/material";
import { FieldArray } from "react-final-form-arrays";
import { ChildFields } from "./ChildFields";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormSpy } from "react-final-form";
import { useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { useFieldValue } from "../../Common/useFieldValue";

export interface ChildrenFieldsProps {}

//Start at a weird number just in case we're using placeholder
// data that has manually typed 1s and 2s
let childIdCounter = 1000;

export const ChildrenEditor = React.memo(
  function ChildrenEditor({}: ChildrenFieldsProps) {
    const { t, iu } = useTranslations("children");
    const { t: tCommon, iu: iuCommon } = useTranslations("common");

    const overTenChildren = useFieldValue<"Yes" | "No" | undefined>(
      "overTenChildren",
    );

    const finishedAddingChildren = useFieldValue<boolean>(
      "finishedAddingChildren",
    );

    return (
      <>
        <FieldArray
          name="children"
          render={({ fields }) => (
            <Stack divider={<Divider />} gap={5}>
              {fields.map((name, index) => (
                <Stack direction="row" gap={1}>
                  <Box flex={1}>
                    <ChildFields key={name} name={name} index={index} />
                  </Box>
                  <Button
                    variant="outlined"
                    sx={{ minWidth: 0 }}
                    onClick={() => fields.remove(index)}
                    color="error"
                    disabled={
                      fields.length == (overTenChildren === "Yes" ? 11 : 1)
                    }
                  >
                    <DeleteIcon fontSize="small" />
                  </Button>
                </Stack>
              ))}

              <FormSpy
                subscription={{ valid: true }}
                render={({ valid, form: { submit, mutators } }) => (
                  <Stack direction="row" gap={2}>
                    <Button
                      onClick={() =>
                        !valid && !finishedAddingChildren
                          ? submit()
                          : fields.push({ id: childIdCounter++ })
                      }
                      startIcon={<AddIcon />}
                      size="large"
                      variant="outlined"
                      sx={{ flex: 1 }}
                    >
                      {appendWithoutHtml(t, iu, (t) => t.addChildren, " / ")}
                    </Button>
                    <Button
                      variant="contained"
                      size="large"
                      //A sneaky hack: If someone clicks Next with invalid fields, we want to show the validation errors
                      // for those fields. The easiest way to do that is to just submit the form, but we don't want them
                      // to submit the form when the fields are actually valid, so we only submit IF the fields are
                      // invalid. Otherwise, we progress like normal
                      onClick={() =>
                        !valid ? submit() : mutators.finishedAddingChildren()
                      }
                    >
                      {appendWithoutHtml(
                        tCommon,
                        iuCommon,
                        (t) => t.next,
                        " / ",
                      )}
                    </Button>
                  </Stack>
                )}
              />
            </Stack>
          )}
        />
      </>
    );
  },
);
