import { stripHtml } from "string-strip-html";
import { SelectFormFieldOption } from "./Fields/SelectFormField";

export function appendWithHtml<T>(
  first: T,
  second: T,
  getField: (item: T) => string | undefined,
  separator = "",
) {
  const firstText = getField(first);
  const secondText = getField(second);

  return appendNullables(firstText, secondText, separator);
}

export function appendWithoutHtml<T>(
  first: T,
  second: T,
  getField: (item: T) => string | undefined,
  separator = "",
) {
  const firstText = getField(first);
  const secondText = getField(second);

  return stripHtml(appendNullables(firstText, secondText, separator)).result;
}

export function mergeIntoSelectOptions<T>(
  first: T,
  second: T,
  values: T,
  getArray: (item: T) => string[],
) {
  const firstArray = getArray(first);
  const secondArray = getArray(second);
  const valuesArray = getArray(values);

  //They should never be different but I'd rather not throw an error in the event that they are
  const largestSize = Math.max(firstArray.length, secondArray.length);

  const result: SelectFormFieldOption[] = [];

  for (let j = 0; j < largestSize; j++) {
    result.push({
      value: valuesArray[j],
      text: stripHtml(
        appendNullables(
          firstArray.length > j ? firstArray[j] : undefined,
          secondArray.length > j ? secondArray[j] : undefined,
          " / ",
        ),
      ).result,
    });
  }

  return result;
}

function appendNullables(
  firstText?: string,
  secondText?: string,
  separator = "",
) {
  if (firstText) {
    if (secondText) {
      return firstText + separator + secondText;
    } else {
      return firstText;
    }
  } else {
    if (secondText) {
      return secondText;
    } else {
      return "";
    }
  }
}
