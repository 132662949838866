import React, { createContext, useContext, useMemo } from "react";
import { ApplicationFormSpec } from "./applicationFormSpec";
import { useSelectedLanguage } from "../SelectedLanguageProvider";
import {
  appendWithoutHtml,
  mergeIntoSelectOptions,
} from "../Common/appendInuktitut";
import { SelectFormFieldOption } from "../Common/Fields/SelectFormField";

export interface TranslationData {
  en?: ApplicationFormSpec;
  fr?: ApplicationFormSpec;
  iu?: ApplicationFormSpec;
}

const TranslationContext = createContext<TranslationData | null>(null);

export interface TranslationProviderProps {
  en?: ApplicationFormSpec;
  fr?: ApplicationFormSpec;
  iu?: ApplicationFormSpec;
  children: React.ReactNode;
}

export function TranslationProvider({
  en,
  fr,
  iu,
  children,
}: TranslationProviderProps) {
  return (
    <TranslationContext.Provider value={{ en, fr, iu }}>
      {children}
    </TranslationContext.Provider>
  );
}

interface TranslationResult<Key extends keyof ApplicationFormSpec> {
  t: ApplicationFormSpec[Key];
  iu: ApplicationFormSpec[Key];
}

export function useFullTranslationObject() {
  const result = useContext(TranslationContext);

  if (result === null) {
    throw new Error(
      "useTranslation() cannot be used without being wrapped by a TranslationProvider",
    );
  }

  if (result.en == null || result.fr == null || result.iu == null) {
    return undefined;
  }

  return result as Required<TranslationData>;
}

export function useAllTranslations() {
  const result = useContext(TranslationContext);
  const { language } = useSelectedLanguage();

  if (result === null) {
    throw new Error(
      "useTranslation() cannot be used without being wrapped by a TranslationProvider",
    );
  }

  if (result.en == null || result.fr == null || result.iu == null) {
    throw new Error("Translations not loaded yet");
  }

  return {
    t: language === "en" ? result.en : result.fr,
    iu: result.iu,
  };
}

export function useEnglishTranslations() {
  const result = useContext(TranslationContext);

  if (result === null) {
    throw new Error(
      "useTranslation() cannot be used without being wrapped by a TranslationProvider",
    );
  }

  return result.en ?? "";
}

export function useTranslations<Key extends keyof ApplicationFormSpec>(
  key: Key,
): TranslationResult<Key> {
  const { t, iu } = useAllTranslations();

  return {
    t: t[key],
    iu: iu[key],
  };
}

export function useTranslatedField(
  getString: (t: ApplicationFormSpec) => string | undefined,
  separator = "",
) {
  const { t, iu } = useAllTranslations();

  return useMemo(() => {
    if (t == null || iu == null) {
      return "";
    }

    return appendWithoutHtml(t, iu, getString, separator);
  }, [t, iu, getString, separator]);
}

export function useTranslatedSelectOptions(
  getArray: (t: ApplicationFormSpec) => string[],
) {
  const result = useContext(TranslationContext);
  const { language } = useSelectedLanguage();

  if (result === null) {
    throw new Error(
      "useTranslation() cannot be used without being wrapped by a TranslationProvider",
    );
  }

  if (result.en == null || result.fr == null || result.iu == null) {
    throw new Error("Translations not loaded yet");
  }

  const { en, fr, iu } = result;

  return useMemo(
    () => mergeIntoSelectOptions(language === "en" ? en : fr, iu, en, getArray),
    [en, fr, language, iu, getArray],
  );
}
