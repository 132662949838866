import React from "react";
import { buildApplicationFlow } from "./applicationFormFlow";
import { FormFlow } from "../Common/FormFlow";
import { ApplicationFormTranslations } from "../Common/API/translationApi";
import { useSelectedLanguage } from "../SelectedLanguageProvider";
import { Box, Card, CardContent, Skeleton, Stack } from "@mui/material";

export interface FormContentWrapperProps {
  translations?: ApplicationFormTranslations;
}

//This component solely exists for development convenience; by wrapping the form flow in
// component that's completely separate from the root Form, every time the form flow
// is updated, it no longer triggers a re-render on the entire form, causing you to lose
// your values and fill in everything all over again. By making it a separate component
// it only needs to re-render the form segments, which means you now keep all your
// values as you make updates.

export function FormContentWrapper({ translations }: FormContentWrapperProps) {
  const { language } = useSelectedLanguage();

  const t = language === "en" ? translations?.en : translations?.fr;
  const iu = translations?.iu;

  if (!t || !iu) {
    return (
      <Card>
        <CardContent>
          <Skeleton height={40} />
          <Box pl={5}>
            <Skeleton height={32} />
            <Stack direction="row" gap={3}>
              <Skeleton height={32} width={100} />
              <Skeleton height={32} width={100} />
            </Stack>
          </Box>
        </CardContent>
      </Card>
    );
  }

  return <FormFlow flow={buildApplicationFlow(t, iu)} />;
}
