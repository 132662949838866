import React from "react";
import { Box, Grid, Stack, useMediaQuery, useTheme } from "@mui/material";
import {
  DateFormField,
  RadioFormField,
  TextFormField,
} from "../../Common/Fields";
import { CFIChild } from "../cfiFormResult";
import { useTranslatedField } from "../TranslationProvider";
import { DeepPartial } from "../../Common/common";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { useFieldValue } from "../../Common/useFieldValue";

export interface ParentNumberFieldsProps {
  name: string;
}

export function ParentNumberFields({ name }: ParentNumberFieldsProps) {
  const { md, lg, sm } = useBreakpoints();

  const beneficiaryLabel = useTranslatedField(
    (t) => t.children.parentNumber.beneficiary,
    " / ",
  );
  const nLabel = useTranslatedField((t) => t.children.parentNumber.n, " / ");
  const dateOfBirthLabel = useTranslatedField(
    (t) => t.children.parentNumber.dateOfBirth,
    " / ",
  );
  const nameLabel = useTranslatedField(
    (t) => t.children.parentNumber.fullName,
    " / ",
  );

  const parentNumberType = useFieldValue<string | undefined>(
    `${name}.parent.numberType`,
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} lg={6}>
        <RadioFormField
          name={`${name}.parent.numberType`}
          label={(t) => t.children.parentNumber.type}
          options={[
            {
              value: "beneficiary#",
              text: (t) => t.children.parentNumber.beneficiary,
            },
            {
              value: "n#",
              text: (t) => t.children.parentNumber.n,
            },
          ]}
          required
          showSpace
        />
      </Grid>
      <Grid item xs={12} lg={6}>
        <Stack direction={{ lg: "column", sm: "row", xs: "column" }} gap={1}>
          <Box flex={1}>
            <TextFormField
              name={`${name}.parent.number`}
              allowedContent="numeric"
              maxLength={parentNumberType === "beneficiary#" ? 5 : 8}
              label={
                parentNumberType === "beneficiary#" ? beneficiaryLabel : nLabel
              }
              required
              showSpace={!lg}
            />
          </Box>
          <Box flex={1}>
            <TextFormField
              name={`${name}.parent.fullName`}
              allowedContent="alpha"
              label={nameLabel}
              required
              showSpace={!sm}
            />
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
}
