import React from "react";
import {
  RadioFormField,
  TextFormField,
  YesNoFormField,
} from "../../Common/Fields";
import { Box, Collapse, Grid } from "@mui/material";
import { useFieldValue } from "../../Common/useFieldValue";
import { useTranslatedField } from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";

function applyNamespace(namespace: string | undefined, name: string) {
  return namespace ? `${namespace}.${name}` : name;
}

export interface ServiceFirstTimeRequestProps {
  namespace?: string;
}

export function ServiceFirstTimeRequest({
  namespace,
}: ServiceFirstTimeRequestProps) {
  const firstTimeRequest = useFieldValue<"Yes" | "No">(
    applyNamespace(namespace, "firstTimeRequest"),
  );

  const stepsTakenPlaceholder = useTranslatedField(
    (t) => t.service.regularStepsTaken?.placeholder,
    " / ",
  );

  return (
    <>
      <RadioFormField
        name={applyNamespace(namespace, "firstTimeRequest")}
        label={(t) => t.service.regularFirstTimeRequest.question}
        options={[
          {
            value: "Yes",
            text: (t) => t.service.regularFirstTimeRequest.yes,
          },
          {
            value: "No",
            text: (t) => t.service.regularFirstTimeRequest.no,
          },
        ]}
        required
        showSpace
      />
      <Collapse in={firstTimeRequest === "No"} unmountOnExit>
        <Box pt={1}>
          <ConvertToMui
            getField={(t) => t.service.regularStepsTaken?.question}
          />
          <TextFormField
            name={applyNamespace(namespace, "stepsTakenSinceLastRequest")}
            label={stepsTakenPlaceholder}
            multiline
            required
            showSpace
            maxLength="unlimited"
          />
        </Box>
      </Collapse>
    </>
  );
}
