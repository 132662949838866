import React from "react";
import { CheckboxFormField, CheckboxFormFieldProps } from "./CheckboxFormField";
import { useTranslations } from "../../ApplicationForm/TranslationProvider";
import { appendWithoutHtml } from "../appendInuktitut";

export interface IUnderstandCheckboxProps
  extends Omit<CheckboxFormFieldProps, "label" | "mustBeTrue"> {}

export function IUnderstandCheckbox(props: IUnderstandCheckboxProps) {
  const { t, iu } = useTranslations("common");

  return (
    <CheckboxFormField
      {...props}
      label={appendWithoutHtml(t, iu, (t) => t.iUnderstand, " / ")}
      mustBeTrue
    />
  );
}
