import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { ServiceFormSpec } from "../applicationFormSpec";
import { CFIFormResult } from "../cfiFormResult";
import {
  RadioFormField,
  TextFormField,
  YesNoFormField,
} from "../../Common/Fields";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { ServiceEditor } from "./ServiceEditor";
import React from "react";
import { ServiceSegment } from "./ServiceSegment";

export type ServiceKey =
  | "firstTimePrompt"
  | "existingRequestFileNumber"
  | "stepsTaken"
  | "services";

export function buildServiceFlow(
  t: ServiceFormSpec,
  iu: ServiceFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "firstTimePrompt",
      element: (
        <RadioFormField
          name="firstTimeRequest"
          label={(t) => t.service.projectFirstTimeRequest.question}
          options={[
            {
              value: "Yes",
              text: (t) => t.service.projectFirstTimeRequest.yes,
            },
            {
              value: "No",
              text: (t) => t.service.projectFirstTimeRequest.no,
            },
          ]}
          required
          showSpace
        />
      ),
      transitions: [
        {
          to: "existingRequestFileNumber",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            r.firstTimeRequest === "No",
        },
        { to: "services", on: (r) => r.firstTimeRequest != null },
      ],
    },

    {
      id: "existingRequestFileNumber",
      element: (
        <>
          <ConvertToMui
            getField={(t) => t.service.existingRequestFileNumber.question}
          />
          <TextFormField
            name="existingRequestFileNumber"
            required
            placeholder={appendWithoutHtml(
              t,
              iu,
              (t) => t.existingRequestFileNumber.placeholder,
              " / ",
            )}
          />
        </>
      ),
      transitions: [
        {
          to: "stepsTaken",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            r.existingRequestFileNumber != null,
        },
      ],
    },

    {
      id: "stepsTaken",
      element: (
        <>
          <ConvertToMui
            getField={(t) => t.service.projectStepsTaken?.question}
          />
          <TextFormField
            name="stepsTakenSinceLastRequest"
            multiline
            required
            placeholder={appendWithoutHtml(
              t,
              iu,
              (t) => t.projectStepsTaken?.placeholder,
              " / ",
            )}
          />
        </>
      ),
      transitions: [
        {
          to: "services",
          on: (r) => r.stepsTakenSinceLastRequest != null,
        },
      ],
    },

    {
      id: "services",
      element: <ServiceSegment />,
      transitions: [
        {
          to: "documents",
          on: (r) => r.finishedAddingServices,
        },
      ],
    },
  ];
}
