import React from "react";
import { Grid, Typography, useMediaQuery, useTheme } from "@mui/material";
import {
  RadioFormField,
  SelectFormField,
  TextFormField,
} from "../../Common/Fields";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import PeopleIcon from "@mui/icons-material/People";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import PersonIcon from "@mui/icons-material/Person";
import BusinessIcon from "@mui/icons-material/Business";
import TelephoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import { contactPreferences } from "../Applicant/ApplicantInfo";
import { Payor, payors } from "../cfiFormResult";
import { useFieldValue } from "../../Common/useFieldValue";
import { useBreakpoints } from "../../Common/useBreakpoints";

export interface PaymentApplicantInfoProps {}

export function PaymentApplicantInfo({}: PaymentApplicantInfoProps) {
  const { t, iu } = useTranslations("applicant");

  const { sm, md } = useBreakpoints();
  const relationship = useFieldValue<Payor>("applicant.relationship");
  const showCustom =
    relationship === "organization" || relationship === "serviceProvider";

  const pleaseSpecify = useTranslatedField(
    (t) => t.common.pleaseSpecify,
    " / ",
  );
  const pleaseSpecifyServiceProvider = useTranslatedField(
    (t) => t.service.whoIsPaying.specifyServiceProvider,
    " / ",
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <RadioFormField
          name="applicant.relationship"
          label={(t) => t.payment.relationship.prompt}
          options={payors.map((p) => ({
            value: p,
            text: (t) => t.payment.relationship.options[p],
          }))}
          icon={<PeopleIcon />}
        />
      </Grid>
      {showCustom && (
        <Grid item xs={12}>
          <TextFormField
            name={"applicant.customRelationship"}
            label={
              relationship === "organization"
                ? pleaseSpecify
                : pleaseSpecifyServiceProvider
            }
            showSpace
            required={relationship === "organization"}
          />
        </Grid>
      )}
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.firstName"
          allowedContent="alpha"
          required
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.firstName,
            " / ",
          )}
          icon={<PersonIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.lastName"
          allowedContent="alpha"
          required
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.lastName,
            " / ",
          )}
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.title"
          allowedContent="alpha"
          label={appendWithoutHtml(t, iu, (t) => t.applicantInfo.title, " / ")}
          showSpace={!sm}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextFormField
          name="applicant.telephone"
          allowedContent="numeric"
          maxLength={10}
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.telephone,
            " / ",
          )}
          required
          icon={<TelephoneIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFormField
          name="applicant.email"
          label={appendWithoutHtml(t, iu, (t) => t.applicantInfo.email, " / ")}
          required
          icon={<EmailIcon />}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <RadioFormField
          required
          name="applicant.preferredContact"
          label={(t) => t.applicant.applicantInfo.contactPreference}
          options={contactPreferences.map((c) => ({
            value: c,
            text: (t) => t.applicant.applicantInfo.contactPreferenceOptions[c],
          }))}
          showSpace
        />
      </Grid>
    </Grid>
  );
}
