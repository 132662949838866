import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { ChildrenFormSpec } from "../applicationFormSpec";
import { CFIFormResult } from "../cfiFormResult";
import { ChildrenEditor } from "./ChildrenEditor";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { OverTenChildrenPrompt } from "./OverTenChildrenPrompt";
import { BasicYesNoPrompt } from "../BasicYesNoPrompt";
import { YesNoFormField } from "../../Common/Fields";
import { Box, Grid, Typography } from "@mui/material";
import { AddressFields } from "../../Common/AddressFields";
import { SharedChildrenAddress } from "./SharedChildrenAddress";

export type ChildrenKey =
  | "groupChildrenCount"
  | "childrenLiveAtSameAddress"
  | "sharedChildAddress"
  | "children";

export function buildChildrenFlow(
  t: ChildrenFormSpec,
  iu: ChildrenFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "groupChildrenCount",
      element: <OverTenChildrenPrompt />,
      transitions: [
        {
          to: "childrenLiveAtSameAddress",
          on: (r) => r.overTenChildren === "No",
        },
        {
          to: "children",
          on: (r) => r.overTenChildren === "Yes",
        },
      ],
    },

    {
      id: "childrenLiveAtSameAddress",
      element: (
        <YesNoFormField
          label={(t) => t.children.childrenLiveAtSameAddress}
          name="childrenLiveAtSameAddress"
          showSpace
        />
      ),
      transitions: [
        {
          to: "children",
          on: (r) => r.childrenLiveAtSameAddress === "No",
        },
        {
          to: "sharedChildAddress",
          on: (r) => r.childrenLiveAtSameAddress === "Yes",
        },
      ],
    },

    {
      id: "sharedChildAddress",
      title: appendWithoutHtml(t, iu, (t) => t.sharedChildrenAddress, " / "),
      element: <SharedChildrenAddress />,
      transitions: [
        {
          to: "children",
          on: (r) =>
            r.childrenAddress?.sameAddressAsApplicant ||
            (r.childrenAddress?.mailingAddress != null &&
              r.childrenAddress.postalCode != null &&
              r.childrenAddress.provinceTerritory != null &&
              r.childrenAddress.community != null),
        },
      ],
    },

    {
      id: "children",
      title: appendWithoutHtml(t, iu, (t) => t.cardTitle, " / "),
      element: <ChildrenEditor />,
      transitions: [
        {
          to: "services",
          on: (r) =>
            r.finishedAddingChildren && r.applicationType === "personal",
        },
        {
          to: "firstTimePrompt",
          on: (r) => r.finishedAddingChildren,
        },
      ],
    },
  ];
}
