import React from "react";
import { Box, Divider, Grid } from "@mui/material";
import { useField } from "react-final-form";
import { ServiceEditor } from "./ServiceEditor";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { FundingReason } from "./FundingReason";

export interface ReasonEditorProps {}

export function ServiceSegment({}: ReasonEditorProps) {
  const {
    input: { value: applicationType },
  } = useField<ApplicationType>("applicationType");

  return (
    <>
      {applicationType !== "personal" && (
        <>
          <Grid container spacing={1}>
            <FundingReason showGoal showFundingReason />
          </Grid>
          <Box pt={5} pb={3}>
            <Divider />
          </Box>
        </>
      )}
      <Box mt={2}>
        <ServiceEditor />
      </Box>
    </>
  );
}
