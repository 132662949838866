import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { useTranslatedField } from "../TranslationProvider";
import { FieldArray } from "react-final-form-arrays";
import { DateFormField, TextFormField } from "../../Common/Fields";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import { FormSpy } from "react-final-form";
import { appendWithoutHtml } from "../../Common/appendInuktitut";

//Start at a weird number just in case we're using placeholder
// data that has manually typed 1s and 2s
let childIdCounter = 1000;

export interface PaymentRequestChildrenProps {}

export function PaymentRequestChildren({}: PaymentRequestChildrenProps) {
  const title = useTranslatedField((t) => t.children.cardTitle, " / ");
  const firstNameLabel = useTranslatedField((t) => t.children.firstName, " / ");
  const middleNameLabel = useTranslatedField(
    (t) => t.children.middleName,
    " / ",
  );
  const lastNameLabel = useTranslatedField((t) => t.children.lastName, " / ");
  const dateOfBirthLabel = useTranslatedField(
    (t) => t.children.dateOfBirth,
    " / ",
  );
  const addChildren = useTranslatedField((t) => t.children.addChildren, " / ");
  const next = useTranslatedField((t) => t.common.next, " / ");

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <Stack gap={2}>
      <Typography variant="h5">{title}</Typography>
      <FieldArray
        name="children"
        render={({ fields }) => (
          <Stack divider={<Divider />} gap={2}>
            {fields.map((name, index) => (
              <Stack key="name" direction="row" gap={2}>
                <Grid flex={1} container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <TextFormField
                      name={`${name}.firstName`}
                      label={firstNameLabel}
                      icon={<PersonIcon />}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextFormField
                      name={`${name}.middleName`}
                      label={middleNameLabel}
                      showSpace={!sm}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <TextFormField
                      name={`${name}.lastName`}
                      label={lastNameLabel}
                      showSpace={!sm}
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <DateFormField
                      name={`${name}.dateOfBirth`}
                      label={dateOfBirthLabel}
                      required
                      showSpace
                    />
                  </Grid>
                </Grid>
                <Button
                  color="error"
                  variant="outlined"
                  disabled={fields.length == 1}
                  onClick={() => fields.remove(index)}
                  sx={{ minWidth: 0 }}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Stack>
            ))}
            <Stack direction="row" gap={2}>
              <Button
                onClick={() => fields.push({ id: childIdCounter++ })}
                variant="outlined"
                sx={{ flex: 1 }}
              >
                {addChildren}
              </Button>
              <FormSpy
                subscription={{ valid: true }}
                render={({ valid, form: { submit, mutators } }) => (
                  <Button
                    variant="contained"
                    //A sneaky hack: If someone clicks Next with invalid fields, we want to show the validation errors
                    // for those fields. The easiest way to do that is to just submit the form, but we don't want them
                    // to submit the form when the fields are actually valid, so we only submit IF the fields are
                    // invalid. Otherwise, we progress like normal
                    onClick={() =>
                      !valid ? submit() : mutators.finishedAddingChildren()
                    }
                  >
                    {next}
                  </Button>
                )}
              />
            </Stack>
          </Stack>
        )}
      />
    </Stack>
  );
}
