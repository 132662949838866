import React from "react";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { SelectFormField } from "../../Common/Fields";
import {
  useTranslatedSelectOptions,
  useTranslatedField,
} from "../TranslationProvider";

export interface SelectLocationInNunavikProps {}

export function SelectLocationInNunavik({}: SelectLocationInNunavikProps) {
  const options = useTranslatedSelectOptions((t) => t.common.communities);
  const placeholder = useTranslatedField((t) => t.project.locationPlaceholder);

  return (
    <>
      <ConvertToMui getField={(t) => t.project.selectLocationInNunavik} />
      <SelectFormField
        name="projectLocation"
        options={options}
        label={placeholder}
      />
    </>
  );
}
