import { DateField, DatePicker } from "@mui/x-date-pickers";
import React from "react";
import { Field } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import dayjs from "dayjs";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

export interface DateFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: string;
  required?: boolean;
}

export const DateFormField: React.FC<DateFormFieldProps> = ({
  name,
  label,
  required,
  ...wrapperProps
}) => {
  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / ",
  );

  return (
    <FieldWrapper {...wrapperProps}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? thisFieldIsRequired : undefined
        }
        render={({ input: { onChange, value, ...input }, meta }) => (
          <DatePicker
            label={label}
            slotProps={{
              textField: {
                required,
                fullWidth: true,
                inputProps: input,
                color: meta.touched && meta.error ? "error" : undefined,
                helperText: meta.touched && meta.error,
              },
            }}
            format="YYYY/MM/DD"
            value={value != null && value != "" ? dayjs(value) : null}
            onChange={(d) => onChange(d?.toJSON())}
          />
        )}
      />
    </FieldWrapper>
  );
};
