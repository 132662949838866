import React from "react";
import { Box, Stack } from "@mui/material";

export interface FieldWrapperProps {
  children: React.ReactNode;
  icon?: React.ReactNode;
  showSpace?: boolean;
}

export function FieldWrapper({ icon, children, showSpace }: FieldWrapperProps) {
  return (
    <Stack direction="row">
      {(icon || showSpace) && (
        <Box minWidth={32} color="primary.main" mt={2} mx={1}>
          {icon}
        </Box>
      )}
      <Box flex={1}>{children}</Box>
    </Stack>
  );
}
