import React from "react";
import { ExtendedTable } from "@torqit/crew-2ls";
import { Box, Stack, Typography } from "@mui/material";
import {
  calculateTotal,
  useRequestSummaryColumns,
} from "./useRequestSummaryColumns";
import { useTranslations } from "../../TranslationProvider";
import { ApplicationType } from "../../Elimination/SelectApplicationType";
import { CFIChild, CFIService } from "../../cfiFormResult";

export interface RequestSummaryProps {
  applicationType?: ApplicationType;
  services?: CFIService[];
  children?: CFIChild[];
}

const currencyFormat = new Intl.NumberFormat("en-CA", {
  style: "currency",
  currency: "CAD",
});

export function RequestSummary({
  applicationType,
  services,
  children,
}: RequestSummaryProps) {
  const columns = useRequestSummaryColumns(children, applicationType);
  const { t, iu } = useTranslations("conclusion");

  return (
    <Box>
      <ExtendedTable
        data={services}
        columns={columns}
        getDataKey={(s) => s.service}
      />
    </Box>
  );
}
