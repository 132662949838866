import {
  Box,
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface MultiSelectFormFieldOption {
  value: number | string;
  text: number | string;
}

function findTextFor(
  value: any,
  options: (string | MultiSelectFormFieldOption)[],
) {
  const result = options.find((o) =>
    typeof o === "string" ? value === o : value === o.value,
  );

  if (result) {
    return typeof result === "string" ? result : result.text;
  }

  return "Unknown";
}

export interface MultiSelectFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: string;
  required?: boolean;
  options: (string | MultiSelectFormFieldOption)[];
}

export function MultiSelectFormField({
  name,
  label,
  required,
  options,
  ...wrapperProps
}: MultiSelectFormFieldProps) {
  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / ",
  );

  return (
    <FieldWrapper {...wrapperProps}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? thisFieldIsRequired : undefined
        }
        render={({ input: { value, ...input }, meta }) => {
          const usableValue = !Array.isArray(value)
            ? []
            : (value as (string | number)[]);

          return (
            <TextField
              select
              label={label}
              SelectProps={{
                multiple: true,
                MenuProps: MenuProps,
                renderValue: (selected: any) => (
                  <Box whiteSpace="normal">
                    {selected
                      .map((s: any) => findTextFor(s, options))
                      .join(", ")}
                  </Box>
                ),
              }}
              fullWidth
              value={usableValue}
              inputProps={input}
              variant="filled"
              error={meta.touched && meta.error}
              helperText={meta.touched && meta.error}
            >
              {options.map((option) =>
                typeof option === "string" ? (
                  <MenuItem key={option} value={option}>
                    <Checkbox checked={usableValue.indexOf(option) > -1} />
                    <ListItemText primary={option} />
                  </MenuItem>
                ) : (
                  <MenuItem key={option.value} value={option.value}>
                    <Checkbox
                      checked={usableValue.indexOf(option.value) > -1}
                    />
                    <ListItemText primary={option.text} />
                  </MenuItem>
                ),
              )}
            </TextField>
          );
        }}
      />
    </FieldWrapper>
  );
}
