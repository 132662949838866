import {
  Box,
  Card,
  CardContent,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { green } from "@mui/material/colors";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  useAllTranslations,
  useFullTranslationObject,
} from "./ApplicationForm/TranslationProvider";

interface TranslatedThankYouProps {}

function TranslatedThankYou({}: TranslatedThankYouProps) {
  const { t, iu } = useAllTranslations();

  return (
    <Box>
      <Typography variant="h5" color={green["900"]}>
        {t.conclusion.thankYouForYourSubmission}
      </Typography>
      <Typography variant="h5" color={green["900"]}>
        {iu.conclusion.thankYouForYourSubmission}
      </Typography>
    </Box>
  );
}

export interface SuccessPageProps {}

export const SuccessPage: React.FC<SuccessPageProps> = ({}) => {
  const translations = useFullTranslationObject();

  return (
    <Stack alignItems="center" mt={4}>
      <Card sx={{ display: "inline-block" }}>
        <CardContent>
          <Stack
            direction="row"
            gap={4}
            alignItems="center"
            justifyContent="center"
            px={5}
            py={2}
          >
            <Box fontSize={80} lineHeight={1}>
              <CheckCircleIcon color="success" fontSize="inherit" />
            </Box>
            {!translations && (
              <Box>
                <Typography variant="h5" color={green["900"]}>
                  <Skeleton width={300} />
                </Typography>
                <Typography variant="h5" color={green["900"]}>
                  <Skeleton width={300} />
                </Typography>
              </Box>
            )}
            {translations && <TranslatedThankYou />}
          </Stack>
        </CardContent>
      </Card>
    </Stack>
  );
};
