import React from "react";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { FormNode } from "../../Common/FormFlow";
import { ConclusionFormSpec } from "../applicationFormSpec";
import { CFIFormResult } from "../cfiFormResult";
import { Documents } from "./Documents";
import { Conclusion } from "./Conclusion";

export type ConclusionKey = "documents" | "conclusion";

export function buildConclusionFlow(): FormNode<
  CFIFormResult,
  CFIApplicationKeys
>[] {
  return [
    {
      id: "documents",
      element: <Documents />,
      transitions: [{ to: "conclusion", on: (r) => r.finishedAddingDocuments }],
    },

    {
      id: "conclusion",
      element: <Conclusion />,
    },
  ];
}
