import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import React from "react";
import { Field, FieldRenderProps, useField } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import { ApplicationFormSpec } from "../../ApplicationForm/applicationFormSpec";
import {
  useAllTranslations,
  useTranslatedField,
} from "../../ApplicationForm/TranslationProvider";
import { stripHtml } from "string-strip-html";

export interface RadioFormFieldOption {
  value: string | number;
  text?: (t: ApplicationFormSpec) => string | undefined;
}

//Courtesy of https://stackoverflow.com/questions/58099124/how-to-use-custom-radio-component-with-react-final-form
const RadioWrapper = (
  props: FieldRenderProps<string | number, HTMLElement, string | number> & {
    disabled?: boolean;
    required?: boolean;
  },
) => {
  const {
    disabled,
    input: { checked, value, name, onChange, required, ...restInput },
  } = props;

  return (
    <Radio
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={checked}
      value={value}
      required={props.required}
      disabled={disabled}
    />
  );
};

export interface RadioFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: (t: ApplicationFormSpec) => string | undefined;
  options: RadioFormFieldOption[] | string[];
  required?: boolean;
  disabled?: boolean;
  vertical?: boolean;
}

export const RadioFormField: React.FC<RadioFormFieldProps> = ({
  name,
  label,
  options,
  required,
  disabled,
  vertical,
  ...wrapperProps
}) => {
  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / ",
  );

  const {
    meta: { error, touched },
  } = useField(name, {
    validate: (v) => (required && v == null ? thisFieldIsRequired : undefined),
  });

  const { t, iu } = useAllTranslations();

  return (
    <FieldWrapper {...wrapperProps}>
      <FormControl>
        {label && (
          <FormLabel id={`${name}-radio-buttons-group-label`}>
            <Typography>
              {stripHtml(label(t) ?? "").result}
              {required && "*"}
            </Typography>
            <Typography>
              {stripHtml(label(iu) ?? "").result}
              {required && "*"}
            </Typography>
          </FormLabel>
        )}
        <RadioGroup
          aria-labelledby={`${name}-radio-buttons-group-label`}
          row={!vertical}
        >
          {options.map((o) => (
            <FormControlLabel
              key={typeof o === "string" ? o : o.value}
              label={
                typeof o === "string" ? (
                  o
                ) : o.text ? (
                  <>
                    <Typography variant="body2">
                      {stripHtml(o.text(t) ?? "").result}
                    </Typography>
                    <Typography variant="body2">
                      {stripHtml(o.text(iu) ?? "").result}
                    </Typography>
                  </>
                ) : (
                  o.value
                )
              }
              sx={{ padding: 1 }}
              control={
                <Field
                  name={name}
                  value={typeof o === "string" ? o : o.value}
                  type="radio"
                  render={({ input, meta }) => (
                    <RadioWrapper
                      input={input}
                      required={required}
                      meta={meta}
                      disabled={disabled}
                    />
                  )}
                />
              }
            />
          ))}
        </RadioGroup>
        {(touched || disabled) && error && (
          <FormHelperText error>{error}</FormHelperText>
        )}
      </FormControl>
    </FieldWrapper>
  );
};
