import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { ChildrenFormSpec, ProjectFormSpec } from "../applicationFormSpec";
import { CFIFormResult, ProjectCFIFormResult } from "../cfiFormResult";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { ProjectFields } from "./ProjectFields";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import {
  RadioFormField,
  SelectFormField,
  TextFormField,
} from "../../Common/Fields";
import { SelectLocationInNunavik } from "./SelectLocationInNunavik";
import React from "react";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { DeepPartial } from "../../Common/common";

export type ProjectKey =
  | "projectFields"
  | "projectLocation"
  | "projectLocationInNunavik"
  | "projectLocationOutsideNunavik";

export function buildProjectFlow(
  t: ProjectFormSpec,
  iu: ProjectFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "projectFields",
      element: <ProjectFields />,
      title: appendWithoutHtml(t, iu, (t) => t.projectInfo, " / "),
      transitions: [
        {
          to: "projectLocation",
          on: (t) =>
            isProject(t) &&
            t.approximateChildCount != null &&
            //We need to check specifically undefined here since 0 is a valid value
            t.minChildAge !== undefined &&
            t.maxChildAge !== undefined &&
            t.projectStartDate !== undefined,
        },
      ],
    },

    {
      id: "projectLocation",
      element: (
        <>
          <ConvertToMui getField={(t) => t.project.projectInNunavik.prompt} />
          <RadioFormField
            name="projectInNunavik"
            options={[
              {
                value: "Yes",
                text: (t) => t.project.projectInNunavik.yes,
              },
              {
                value: "No",
                text: (t) => t.project.projectInNunavik.no,
              },
            ]}
          />
        </>
      ),
      transitions: [
        {
          to: "projectLocationInNunavik",
          on: (t) => isProject(t) && t.projectInNunavik === "Yes",
        },
        {
          to: "projectLocationOutsideNunavik",
          on: (t) => isProject(t) && t.projectInNunavik === "No",
        },
      ],
    },

    {
      id: "projectLocationInNunavik",
      element: <SelectLocationInNunavik />,
      transitions: [
        {
          to: "firstTimePrompt",
          on: (t) => isProject(t) && t.projectLocation != null,
        },
      ],
    },

    {
      id: "projectLocationOutsideNunavik",
      element: (
        <>
          <ConvertToMui
            getField={(t) => t.project.selectLocationOutsideNunavik}
          />
          <TextFormField
            name="projectLocation"
            allowedContent="alpha"
            placeholder={appendWithoutHtml(t, iu, (t) => t.locationPlaceholder)}
          />
        </>
      ),
      transitions: [
        {
          to: "firstTimePrompt",
          on: (t) => isProject(t) && t.projectLocation != null,
        },
      ],
    },
  ];
}

function isProject(
  form: DeepPartial<CFIFormResult>,
): form is DeepPartial<ProjectCFIFormResult> {
  return (
    form.applicationType === "oneTimeProject" ||
    form.applicationType === "structuringProject"
  );
}
