import React from "react";
import {
  RadioFormField,
  RadioFormFieldOption,
  RadioFormFieldProps,
} from "./RadioFormField";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

export interface YesNoFormFieldProps
  extends Omit<RadioFormFieldProps, "options"> {
  allowUnsure?: boolean;
}

export function YesNoFormField({ allowUnsure, ...props }: YesNoFormFieldProps) {
  const yesNo: RadioFormFieldOption[] = [
    { value: "Yes", text: (t) => t.common.yes },
    { value: "No", text: (t) => t.common.no },
  ];

  return (
    <RadioFormField
      {...props}
      options={
        allowUnsure
          ? yesNo.concat([{ value: "Unsure", text: (t) => t.common.unsure }])
          : yesNo
      }
    />
  );
}
