import React from "react";
import { Grid } from "@mui/material";
import {
  DateFormField,
  MultiSelectFormField,
  TextFormField,
} from "../../Common/Fields";
import { CFIChild } from "../cfiFormResult";
import PeopleIcon from "@mui/icons-material/People";
import { useTranslatedField, useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { ServiceSelector } from "./ServiceSelector";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import { FundingReason } from "./FundingReason";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { PayorFields } from "./PayorFields";

export interface ServiceFieldsProps {
  name: string;
  index: number;
}

export const ServiceFields = React.memo(function ({
  name,
  index,
}: ServiceFieldsProps) {
  const { sm, md } = useBreakpoints();
  const { t, iu } = useTranslations("service");

  const frequency = useTranslatedField(
    (t) => t.service.payment.frequency,
    " / ",
  );
  const cost = useTranslatedField((t) => t.service.payment.cost, " / ");
  const serviceType = useTranslatedField((t) => t.service.serviceType, " / ");

  const startDate = useTranslatedField((t) => t.service.startDate, " / ");
  const endDate = useTranslatedField((t) => t.service.endDate, " / ");

  const otherLabel = useTranslatedField((t) => t.common.other, " / ");

  const applicationType = useFieldValue<ApplicationType>("applicationType");
  const children = useFieldValue<CFIChild[]>("children");

  return (
    <Grid container spacing={1}>
      {applicationType === "personal" && (
        <Grid item xs={12} sm={6} md={4}>
          <MultiSelectFormField
            name={`${name}.children`}
            label={appendWithoutHtml(
              t,
              iu,
              (t) => t.serviceLabels.children,
              " / ",
            )}
            options={children.map((c) => ({
              value: c.id,
              text: `${c.firstName} ${c.lastName}`,
            }))}
            icon={<PeopleIcon />}
            required
          />
        </Grid>
      )}
      <ServiceSelector name={name} index={index} />
      <Grid item xs={12}>
        <TextFormField
          name={`${name}.serviceType`}
          label={serviceType}
          multiline
          required
          showSpace
        />
      </Grid>
      <Grid item xs={12} md={applicationType === "personal" ? 3 : 12}>
        <TextFormField
          name={`${name}.cost`}
          label={cost}
          allowedContent="numeric"
          icon={<AttachMoneyIcon />}
          required
        />
      </Grid>
      {applicationType === "personal" && <PayorFields name={name} />}
      {applicationType !== "oneTimeProject" &&
        applicationType !== "structuringProject" && (
          <>
            <Grid item xs={12} sm={6}>
              <DateFormField
                name={`${name}.startDate`}
                label={startDate}
                required
                showSpace
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateFormField name={`${name}.endDate`} label={endDate} />
            </Grid>
          </>
        )}

      {applicationType === "personal" && (
        <FundingReason namespace={name} showFirstTimePrompt />
      )}
    </Grid>
  );
});
