import React, { useEffect, useRef } from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Fade,
  Stack,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

export interface FormSegmentProps {
  in?: boolean;
  title?: string;
  children?: React.ReactNode;
  maxWidth?: number;
  end?: boolean;
}

export function FormSegment({
  title,
  children,
  maxWidth,
  end,
  ...props
}: FormSegmentProps) {
  const navigate = useNavigate();
  const backToNRBHSS = useTranslatedField((t) => t.common.backToNRBHSS, " / ");

  return (
    <Collapse in={props["in"]} unmountOnExit>
      <Fade in={props["in"]}>
        <Stack
          py={2}
          alignItems={maxWidth != null ? "center" : undefined}
          gap={5}
        >
          <Box maxWidth={maxWidth}>
            <Card elevation={6}>
              {title && <CardHeader title={title} />}
              <CardContent>{children}</CardContent>
            </Card>
          </Box>
          {end && (
            <Divider sx={{ width: "100%" }}>
              <Button
                color="secondary"
                href="about:blank"
                target="_blank"
                startIcon={<ArrowBackIcon />}
              >
                {backToNRBHSS}
              </Button>
            </Divider>
          )}
        </Stack>
      </Fade>
    </Collapse>
  );
}
