import React from "react";
import { EliminationFormSpec } from "../applicationFormSpec";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { useFormState } from "react-final-form";
import { YesNoFormField } from "../../Common/Fields";
import { CFIFormResult } from "../cfiFormResult";
import { useTranslations } from "../TranslationProvider";
import { Divider } from "@mui/material";

export interface ConfirmHasDocumentsProps {
  formSpec: EliminationFormSpec;
}

export function ConfirmHasDocuments({ formSpec }: ConfirmHasDocumentsProps) {
  const { values } = useFormState<CFIFormResult>({
    subscription: { values: true },
  });

  const { t, iu } = useTranslations("elimination");

  return (
    <>
      <ConvertToMui raw={t.documentCheck.prompt} />
      <ConvertToMui
        raw={t.documentCheck.requiredFiles[values.applicationType]}
        fadeInOut
      />
      <ConvertToMui raw={iu.documentCheck.prompt} />
      <ConvertToMui
        raw={iu.documentCheck.requiredFiles[values.applicationType]}
        fadeInOut
      />
      <YesNoFormField
        name="hasDocuments"
        label={(t) => t.elimination.documentCheck.question}
        showSpace
      />
    </>
  );
}
