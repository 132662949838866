import { createContext, useContext } from "react";

export interface SelectedLanguageData {
  language: "en" | "fr";
}

const SelectedLanguageContext = createContext<SelectedLanguageData | null>(
  null,
);

export interface SelectedLanguageProviderProps {
  language: "en" | "fr";
  children: React.ReactNode;
}

export function SelectedLanguageProvider({
  children,
  language,
}: SelectedLanguageProviderProps) {
  return (
    <SelectedLanguageContext.Provider value={{ language }}>
      {children}
    </SelectedLanguageContext.Provider>
  );
}

export function useSelectedLanguage() {
  const result = useContext(SelectedLanguageContext);

  if (result === null) {
    throw new Error(
      "useSelectedLanguage() cannot be used without being wrapped by a SelectedLanguageProvider",
    );
  }

  return result;
}
