import React from "react";
import { Field } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import {
  TextFieldWrapperProps,
  TextFormFieldWrapper,
} from "./TextFormFieldWrapper";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

export interface TextFormFieldProps
  extends Omit<FieldWrapperProps, "children">,
    TextFieldWrapperProps {
  name: string;
}

export function TextFormField({
  icon,
  showSpace,
  name,
  required,
  ...textFieldProps
}: TextFormFieldProps) {
  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / ",
  );

  return (
    <FieldWrapper icon={icon} showSpace={showSpace}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? thisFieldIsRequired : undefined
        }
        render={({ meta, input }) => (
          <TextFormFieldWrapper
            input={input}
            meta={meta}
            required={required}
            {...textFieldProps}
          />
        )}
      />
    </FieldWrapper>
  );
}
