import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { DateFormField, TextFormField } from "../../Common/Fields";
import { useTranslatedField } from "../TranslationProvider";
import EscalatorWarningIcon from "@mui/icons-material/EscalatorWarning";
import PeopleIcon from "@mui/icons-material/People";

export interface ChildCountProps {}

export function ProjectFields({}: ChildCountProps) {
  const childCount = useTranslatedField((t) => t.project.children.count, " / ");
  const childMinAge = useTranslatedField(
    (t) => t.project.children.minAge,
    " / ",
  );
  const childMaxAge = useTranslatedField(
    (t) => t.project.children.maxAge,
    " / ",
  );

  const startDate = useTranslatedField((t) => t.service.startDate, " / ");
  const endDate = useTranslatedField((t) => t.service.endDate, " / ");

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <TextFormField
            name="approximateChildCount"
            allowedContent="numeric"
            required
            label={childCount}
            icon={<PeopleIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="minChildAge"
            allowedContent="numeric"
            required
            label={childMinAge}
            icon={<EscalatorWarningIcon />}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFormField
            name="maxChildAge"
            allowedContent="numeric"
            required
            label={childMaxAge}
            showSpace={!sm}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateFormField
            name={`projectStartDate`}
            label={startDate}
            required
            showSpace
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DateFormField name={`projectEndDate`} label={endDate} />
        </Grid>
      </Grid>
    </>
  );
}
