import React, { useEffect } from "react";
import { YesNoFormField } from "../../Common/Fields";
import { useField, useForm } from "react-final-form";

export interface OverTenChildrenPromptProps {}

export function OverTenChildrenPrompt({}: OverTenChildrenPromptProps) {
  const {
    input: { value: overTenChildren },
  } = useField<"Yes" | "No">("overTenChildren");

  const { mutators } = useForm();

  useEffect(() => {
    if (overTenChildren === "Yes") {
      mutators.setOverTenChildren();
    } else {
      mutators.setOneChild();
    }
  }, [overTenChildren, mutators]);

  return (
    <YesNoFormField
      name="overTenChildren"
      label={(t) => t.children.groupHasMoreThanTenChildren}
      showSpace
    />
  );
}
