import React from "react";
import { Collapse, Grid, useMediaQuery, useTheme } from "@mui/material";
import { RadioFormField, TextFormField } from "../../Common/Fields";
import NumberIcon from "@mui/icons-material/Tag";
import { useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { ParentNumberFields } from "./ParentNumberFields";
import { useFieldValue } from "../../Common/useFieldValue";

export interface ChildNumberFieldsProps {
  name: string;
}

export function ChildNumberFields({ name }: ChildNumberFieldsProps) {
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));

  const { t, iu } = useTranslations("children");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const childNumberType = useFieldValue<string | undefined>(
    `${name}.childNumberType`,
  );

  return (
    <>
      <Grid
        item
        xs={12}
        md={childNumberType != null && childNumberType !== "No" ? 6 : 12}
      >
        <RadioFormField
          name={`${name}.childNumberType`}
          label={(t) => t.children.childNumber.type}
          options={[
            {
              value: "beneficiary#",
              text: (t) => t.children.beneficiaryNumber,
            },
            {
              value: "n#",
              text: (t) => t.children.nNumber,
            },
            {
              value: "none",
              text: (t) => t.common.no,
            },
          ]}
          required
          icon={<NumberIcon />}
        />
      </Grid>
      {childNumberType != null && childNumberType !== "none" && (
        <Grid item xs={12} md={6}>
          <TextFormField
            allowedContent="numeric"
            name={`${name}.childNumber`}
            maxLength={childNumberType === "beneficiary#" ? 5 : 8}
            label={appendWithoutHtml(
              t,
              iu,
              (t) =>
                childNumberType === "beneficiary#"
                  ? t.childNumber.beneficiary
                  : t.childNumber.n,
              " / ",
            )}
            required
            showSpace={!md}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <Collapse in={childNumberType === "none"} unmountOnExit>
          <ParentNumberFields name={name} />
        </Collapse>
      </Grid>
    </>
  );
}
