import { FormNode } from "../../Common/FormFlow";
import { CFIApplicationKeys } from "../applicationFormFlow";
import { RequestUrgency } from "./RequestUrgency";
import { ApplicantFormSpec } from "../applicationFormSpec";
import { ApplicationContact, CFIFormResult } from "../cfiFormResult";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import {
  CheckboxFormField,
  RadioFormField,
  SelectFormField,
  YesNoFormField,
} from "../../Common/Fields";
import { COMMUNITY_ORGS, OTHER_ORG, SelectOrg } from "./SelectOrg";
import { ApplicantInfo } from "./ApplicantInfo";
import { ContactInfo } from "./ContactInfo";
import {
  appendWithoutHtml,
  mergeIntoSelectOptions,
} from "../../Common/appendInuktitut";
import { IUnderstandCheckbox } from "../../Common/Fields/IUnderstandCheckbox";
import { RelationSelector } from "./RelationSelector";
import { OrganizationEmployeePrompt } from "./OrganizationEmployeePrompt";
import { ParentalConsentProof } from "./ParentalConsentProof";
import { DeepPartial } from "../../Common/common";

export type ApplicantKey =
  | "requestUrgency"
  | "relationType"
  | "parentalConsentProof"
  | "parentalAuthorityProof"
  | "informedOrganization"
  | "organizationIsInNunavik"
  | "selectNunavikOrg"
  | "selectOutsideNunavikOrg"
  | "applicantInfo"
  | "isApplicantContact"
  | "contactInfo";

export function buildApplicantFlow(
  t: ApplicantFormSpec,
  iu: ApplicantFormSpec,
): FormNode<CFIFormResult, CFIApplicationKeys>[] {
  return [
    {
      id: "requestUrgency",
      element: <RequestUrgency />,
      transitions: [
        {
          to: "relationType",
          on: (r) => r.requestUrgency != null,
        },
      ],
    },

    {
      id: "relationType",
      element: <RelationSelector />,
      transitions: [
        {
          to: "informedOrganization",
          on: (r) =>
            r.relationship != null &&
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject"),
        },
        {
          to: "parentalConsentProof",
          on: (r) =>
            r.relationship === "familyMember" ||
            r.relationship === "educational" ||
            r.relationship === "healthcare" ||
            r.relationship === "socialServices" ||
            r.relationship === "communityWorker" ||
            r.relationship === "directionOfYouthProtection" ||
            (r.relationship === "other" && r.customRelationship != null),
        },
        {
          to: "parentalAuthorityProof",
          on: (r) => r.relationship === "fosterParent",
        },
        {
          to: "applicantInfo",
          on: (r) =>
            r.relationship === "parentOrGuardian" || r.relationship === "self",
        },
      ],
    },

    {
      id: "parentalConsentProof",
      element: <ParentalConsentProof />,
      transitions: [
        {
          to: "applicantInfo",
          on: (r) =>
            r.hasParentalConsentOrAuthority &&
            r.applicationType === "personal" &&
            r.relationship === "familyMember",
        },
        {
          to: "informedOrganization",
          on: (r) =>
            r.hasParentalConsentOrAuthority &&
            r.applicationType === "personal" &&
            !isFamily(r),
        },
        {
          to: "organizationIsInNunavik",
          on: (r) =>
            r.applicationType === "personal" && r.hasParentalConsentOrAuthority,
        },
      ],
    },

    {
      id: "parentalAuthorityProof",
      element: (
        <>
          <ConvertToMui getField={(t) => t.applicant.parentalAuthorityProof} />
          <IUnderstandCheckbox name="hasParentalConsentOrAuthority" showSpace />
        </>
      ),
      transitions: [
        {
          to: "applicantInfo",
          on: (r) => r.hasParentalConsentOrAuthority,
        },
      ],
    },

    {
      id: "informedOrganization",
      element: (
        <>
          <ConvertToMui getField={(t) => t.applicant.informedOrganization} />
          <IUnderstandCheckbox name="hasInformedOrganization" showSpace />
        </>
      ),
      transitions: [
        {
          to: "organizationIsInNunavik",
          on: (r) => r.hasInformedOrganization,
        },
      ],
    },

    {
      id: "organizationIsInNunavik",
      element: <OrganizationEmployeePrompt />,

      transitions: [
        {
          to: "applicantInfo",
          on: (r) => r.applicant?.organizationLocation === "no",
        },
        {
          to: "selectNunavikOrg",
          on: (r) => r.applicant?.organizationLocation === "insideNunavik",
        },
        {
          to: "selectOutsideNunavikOrg",
          on: (r) => r.applicant?.organizationLocation === "outsideNunavik",
        },
      ],
    },

    {
      id: "selectNunavikOrg",
      element: (
        <SelectOrg
          getPrompt={(t) => t.applicant.selectNunavikOrg.prompt}
          getOptions={(t) => t.applicant.selectNunavikOrg.options}
          showCustomCommunityOrgs
        />
      ),
      transitions: [
        {
          to: "applicantInfo",
          on: (r) =>
            r.applicant?.organization === OTHER_ORG ||
            r.applicant?.organization === COMMUNITY_ORGS
              ? r.applicant.customOrganization != null
              : r.applicant?.organization != null,
        },
      ],
    },

    {
      id: "selectOutsideNunavikOrg",
      element: (
        <SelectOrg
          getPrompt={(t) => t.applicant.selectOutsideNunavikOrg.prompt}
          getOptions={(t) => t.applicant.selectOutsideNunavikOrg.options}
          alwaysShowCustom
        />
      ),
      transitions: [
        {
          to: "applicantInfo",
          on: (r) =>
            r.applicant?.organization != null &&
            r.applicant.customOrganization != null,
        },
      ],
    },

    {
      id: "applicantInfo",
      title: appendWithoutHtml(t, iu, (t) => t.applicantInfo.cardTitle, " / "),
      element: <ApplicantInfo />,
      transitions: [
        {
          to: "isApplicantContact",
          on: (r) => applicantFilledOut(r.applicant) && !isFamily(r),
        },
        {
          to: "childrenLiveAtSameAddress",
          on: (r) => applicantFilledOut(r.applicant),
        },
      ],
    },

    {
      id: "isApplicantContact",
      element: (
        <YesNoFormField
          label={(t) => t.applicant.isApplicantContact}
          name="isApplicantContact"
          showSpace
        />
      ),
      transitions: [
        {
          to: "contactInfo",
          on: (r) => {
            return r.isApplicantContact === "No";
          },
        },
        {
          to: "projectFields",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            r.isApplicantContact === "Yes",
        },
        {
          to: "groupChildrenCount",
          on: (r) => r.isApplicantContact === "Yes" && !isFamily(r),
        },
      ],
    },

    {
      id: "contactInfo",
      element: <ContactInfo />,
      transitions: [
        {
          to: "groupChildrenCount",
          on: (r) =>
            r.applicationType === "personal" &&
            contactFilledOut(r?.contact) &&
            !isFamily(r),
        },
        {
          to: "projectFields",
          on: (r) =>
            (r.applicationType === "oneTimeProject" ||
              r.applicationType === "structuringProject") &&
            contactFilledOut(r?.contact),
        },
      ],
    },
  ];
}

function applicantFilledOut(
  applicantInfo?: Partial<CFIFormResult["applicant"]>,
) {
  return (
    applicantInfo?.firstName != null &&
    applicantInfo?.lastName != null &&
    applicantInfo?.mailingAddress != null &&
    applicantInfo?.community != null &&
    applicantInfo?.provinceTerritory != null &&
    applicantInfo?.postalCode != null &&
    applicantInfo?.telephone != null &&
    applicantInfo?.email != null &&
    applicantInfo?.preferredContact != null
  );
}

function contactFilledOut(contact?: Partial<ApplicationContact>) {
  return (
    contact?.firstName != null &&
    contact?.lastName != null &&
    contact?.telephone != null &&
    contact?.email != null &&
    contact?.preferredContact != null
  );
}

function isFamily(r: DeepPartial<CFIFormResult>) {
  return (
    r.relationship === "parentOrGuardian" ||
    r.relationship === "self" ||
    r.relationship === "familyMember" ||
    r.relationship === "fosterParent"
  );
}
