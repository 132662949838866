import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";
import { Field } from "react-final-form";
import { FileDropper } from "../FileDropper";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import ClearIcon from "@mui/icons-material/Clear";
import { ApplicationFormSpec } from "../../ApplicationForm/applicationFormSpec";
import { useAllTranslations } from "../../ApplicationForm/TranslationProvider";
import { stripHtml } from "string-strip-html";

export interface FileFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: (t: ApplicationFormSpec) => string | undefined;
  multiple?: boolean;
}

export const FileFormField: React.FC<FileFormFieldProps> = ({
  name,
  label,
  multiple,
  ...wrapperProps
}) => {
  const { t, iu } = useAllTranslations();

  return (
    <FieldWrapper {...wrapperProps}>
      <FormControl fullWidth>
        {label && (
          <FormLabel>
            <Typography variant="h5">
              {stripHtml(label(t) ?? "").result}
            </Typography>
            <Typography variant="h5">
              {stripHtml(label(iu) ?? "").result}
            </Typography>
          </FormLabel>
        )}
        <Field
          name={name}
          type="file"
          render={({ input, meta }) => (
            <Stack direction="row" gap={2}>
              <Box flex={1}>
                <FileDropper
                  onChange={(files) => {
                    if (multiple) {
                      input.onChange(
                        (input.value === null || input.value === ""
                          ? []
                          : input.value
                        ).concat(files),
                      );
                    } else {
                      input.onChange(files);
                    }
                  }}
                  files={input.value}
                  multiple={multiple}
                />
              </Box>
              {input.value != null && input.value.length > 0 && (
                <Button
                  variant="outlined"
                  onClick={() => input.onChange([])}
                  sx={{ minWidth: 0 }}
                >
                  <ClearIcon />
                </Button>
              )}
            </Stack>
          )}
        />
      </FormControl>
    </FieldWrapper>
  );
};
