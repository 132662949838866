import React from "react";
import { ApplicantFormSpec } from "../applicationFormSpec";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { SelectFormField } from "../../Common/Fields";
import UrgencyIcon from "@mui/icons-material/AccessAlarm";
import { useTranslations } from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";

const urgencyTypes = ["urgent", "timeSensitive", "notUrgent"] as const;
export type UrgencyType = (typeof urgencyTypes)[number];

export interface RequestUrgencyProps {}

export function RequestUrgency({}: RequestUrgencyProps) {
  const { t, iu } = useTranslations("applicant");

  return (
    <>
      <ConvertToMui getField={(t) => t.applicant.urgency.question} />
      <SelectFormField
        name="requestUrgency"
        label={appendWithoutHtml(t, iu, (t) => t.urgency.label, " / ")}
        options={urgencyTypes.map((type) => ({
          value: type,
          text: appendWithoutHtml(t, iu, (t) => t.urgency[type].name, " / "),
          subText: appendWithoutHtml(t, iu, (t) => t.urgency[type].description, " / "),
        }))}
        icon={<UrgencyIcon fontSize="large" />}
      />
    </>
  );
}
