import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    primary: { main: "#007298" },
    secondary: { main: "#E2ECF8" },
    info: { main: "#98abf6" },
    success: { main: "#49c065" },
    warning: { main: "#e0b650" },
    error: { main: "#D83D41" },
  },

  typography: {
    fontFamily: '"Muli", sans-serif',
    h1: { fontSize: 36, fontWeight: "bold" },
    h2: { fontSize: 30, fontWeight: "bold" },
  },

  components: {
    MuiTextField: {
      defaultProps: {
        variant: "filled",
      },
    },

    MuiListSubheader: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          lineHeight: "36px",
          margin: 6,
          borderRadius: 8,
        }),
      },
    },
  },
});
