import React from "react";
import { Grid } from "@mui/material";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import {
  appendWithoutHtml,
  mergeIntoSelectOptions,
} from "../../Common/appendInuktitut";
import { GroupedSelectFormFieldOptions } from "../../Common/Fields/SelectFormField";
import ServiceIcon from "@mui/icons-material/HomeRepairService";
import {
  useEnglishTranslations,
  useTranslatedField,
  useTranslations,
} from "../TranslationProvider";
import { useField } from "react-final-form";
import { PaymentClientType } from "../cfiFormResult";
import { useServiceOptions } from "../ServiceOptionsProvider";

export interface PaymentServiceSelectorProps {
  name: string;
}

export function PaymentServiceSelector({ name }: PaymentServiceSelectorProps) {
  const { t, iu } = useTranslations("service");
  const en = useEnglishTranslations();
  const otherLabel = useTranslatedField((t) => t.common.other, " / ");

  const {
    input: { value: service },
  } = useField<string>(`${name}.service`);
  const {
    input: { value: clientType },
  } = useField<PaymentClientType>("clientType");

  const { categories } = useServiceOptions(clientType);

  return (
    <>
      <Grid item xs={12} sm={12} md={service.startsWith("Other") ? 6 : 12}>
        <SelectFormField
          name={`${name}.service`}
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.serviceLabels.services,
            " / ",
          )}
          options={categories ?? []}
          icon={<ServiceIcon />}
          required
        />
      </Grid>
      {service.startsWith("Other") && (
        <Grid item xs={12} sm={12} md={6}>
          <TextFormField
            name={`${name}.customService`}
            label="Custom service"
            required
          />
        </Grid>
      )}
    </>
  );
}
