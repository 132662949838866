import React from "react";
import { Grid } from "@mui/material";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { AddressFields } from "../../Common/AddressFields";

export interface AddressFieldsProps {}

export function PaymentAddressFields({}: AddressFieldsProps) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <ConvertToMui getField={(t) => t.payment.addressPrompt} />
      </Grid>
      <AddressFields namespace="addressInfo" />
    </Grid>
  );
}
