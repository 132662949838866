import React from "react";
import { Typography } from "@mui/material";
import {
  ApplicationFormSpec,
  EliminationFormSpec,
} from "../applicationFormSpec";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { useTranslatedField } from "../TranslationProvider";

type ExitMessage =
  | "noBeneficiaryNumber"
  | "aboveMajorityAge"
  | "exploreFunding"
  | "missingDocuments";

export interface FormExitProps {
  message: ExitMessage;
}

export function FormExit({ message }: FormExitProps) {
  return <ConvertToMui getField={(t) => t.elimination.exit[message]} />;
}
