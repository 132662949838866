import { createBrowserRouter } from "react-router-dom";
import { Layout } from "./Layout";
import { ApplicationForm } from "./ApplicationForm";
import { SuccessPage } from "./SuccessPage";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        path: "",
        element: <ApplicationForm />,
      },
      {
        path: "/success",
        element: <SuccessPage />,
      },
    ],
  },
]);
