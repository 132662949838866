import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import {
  DateFormField,
  SelectFormField,
  TextFormField,
} from "../../Common/Fields";
import PersonIcon from "@mui/icons-material/Person";
import { ChildNumberFields } from "./ChildNumberFields";
import {
  useTranslatedSelectOptions,
  useTranslations,
} from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { ChildrenAddressFields } from "./ChildrenAddressFields";
import { useBreakpoints } from "../../Common/useBreakpoints";
import { useFieldValue } from "../../Common/useFieldValue";
import { ApplicationType } from "../Elimination/SelectApplicationType";
import AnnouncementIcon from "@mui/icons-material/Announcement";

export interface ChildFieldsProps {
  name: string;
  index: number;
}

export const ChildFields = React.memo(function ChildFields({
  name,
  index,
}: ChildFieldsProps) {
  const { sm } = useBreakpoints();

  const { t, iu } = useTranslations("children");
  const communities = useTranslatedSelectOptions((t) => t.common.communities);
  const genders = useTranslatedSelectOptions((t) => t.children.genders);

  const applicationType = useFieldValue<ApplicationType>("applicationType");
  const sameAddress = useFieldValue<"Yes" | "No">("childrenLiveAtSameAddress");

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name={`${name}.firstName`}
          allowedContent="alpha"
          label={appendWithoutHtml(t, iu, (t) => t.firstName, " / ")}
          required
          icon={<PersonIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name={`${name}.middleName`}
          allowedContent="alpha"
          label={appendWithoutHtml(t, iu, (t) => t.middleName, " / ")}
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name={`${name}.lastName`}
          allowedContent="alpha"
          label={appendWithoutHtml(t, iu, (t) => t.lastName, " / ")}
          showSpace={!sm}
          required
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectFormField
          name={`${name}.gender`}
          label={appendWithoutHtml(t, iu, (t) => t.gender, " / ")}
          options={genders}
          showSpace
          required
        />
      </Grid>
      <Grid item xs={12} sm={8}>
        <DateFormField
          name={`${name}.dateOfBirth`}
          label={appendWithoutHtml(t, iu, (t) => t.dateOfBirth, " / ")}
          showSpace={!sm}
          required
        />
      </Grid>
      {sameAddress !== "Yes" && (
        <ChildrenAddressFields name={name} index={index} />
      )}
      <ChildNumberFields name={name} />
    </Grid>
  );
});
