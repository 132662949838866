import React from "react";
import { FileFormField } from "../../Common/Fields/FileFormField";
import { Box, Button, Stack } from "@mui/material";
import { useForm } from "react-final-form";
import { useTranslatedField } from "../TranslationProvider";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { TextFormField } from "../../Common/Fields";
import { appendWithoutHtml } from "../../Common/appendInuktitut";

export interface InvoicesAndOtherDocumentsProps {}

export function InvoicesAndOtherDocuments({}: InvoicesAndOtherDocumentsProps) {
  const { mutators } = useForm();
  const next = useTranslatedField((t) => t.common.next, " / ");

  const commentsPlaceholder = useTranslatedField(
    (t) => t.conclusion.documents.comments.placeholder,
    " / ",
  );

  return (
    <Stack gap={2}>
      <FileFormField
        name="documents.invoices"
        label={(t) => t.conclusion.documents.invoices}
        multiple
      />
      <FileFormField
        name="documents.other"
        label={(t) => t.conclusion.documents.other}
        multiple
      />
      <Box>
        <ConvertToMui
          getField={(t) => t.conclusion.documents.comments.prompt}
        />
        <TextFormField
          name="documentComments"
          placeholder={commentsPlaceholder}
          multiline
        />
        <Box textAlign="right" pt={2}>
          <Button
            variant="contained"
            size="large"
            onClick={() => mutators.finishedAddingDocuments()}
          >
            {next}
          </Button>
        </Box>
      </Box>
    </Stack>
  );
}
