import React, { useMemo, useRef } from "react";
import { useFormState } from "react-final-form";
import { TransitionGroup } from "react-transition-group";
import { buildFormFlow, FormNode } from "./buildFormFlow";
import { FormSegment } from "./FormSegment";
import { FormResult } from "../../ApplicationForm/cfiFormResult";

export interface FormFlowProps {
  flow: FormNode<any, any>[];
}

export function FormFlow({ flow }: FormFlowProps) {
  const { values } = useFormState({
    subscription: { values: true },
  });

  const renderedNodes = useMemo(
    () => buildFormFlow(flow, values),
    [flow, values],
  );

  return (
    <TransitionGroup>
      {renderedNodes.map((n) => (
        <FormSegment
          key={n.id}
          title={n.title}
          maxWidth={n.maxWidth}
          end={n.end}
        >
          {n.element}
        </FormSegment>
      ))}
    </TransitionGroup>
  );
}
