import {
  Box,
  ListSubheader,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import React, { useMemo } from "react";
import { Field } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

export interface SelectFormFieldOption {
  value?: number | string;
  text: number | string;
  subText?: string;
  noBold?: boolean;
  disabled?: boolean;
}

export interface GroupedSelectFormFieldOptions {
  group?: string;
  options: (SelectFormFieldOption | string)[];
}

function isGroup(
  o: SelectFormFieldOption | GroupedSelectFormFieldOptions,
): o is GroupedSelectFormFieldOptions {
  return "group" in o;
}

function renderOption(
  option: string | SelectFormFieldOption | GroupedSelectFormFieldOptions,
): React.ReactNode | React.ReactNode[] {
  if (typeof option === "string") {
    return (
      <MenuItem key={option} value={option} sx={{ display: "block" }}>
        <Typography sx={{ textWrap: "wrap" }}>{option}</Typography>
      </MenuItem>
    );
  }

  if (isGroup(option)) {
    //It'll never actually return a double nested array but I have to make the typing happy
    const group: React.ReactNode[] | React.ReactNode[][] = option.group
      ? [<ListSubheader color="primary">{option.group}</ListSubheader>]
      : [];

    return group.concat(
      option.options.map((subOption) => renderOption(subOption)),
    );
  }

  return (
    <MenuItem
      key={option.value}
      value={option.value}
      sx={{ display: "block" }}
      disabled={option.disabled}
    >
      <Typography
        fontWeight={option.subText && !option.noBold ? "bold" : undefined}
        sx={{ textWrap: "wrap" }}
      >
        {option.text}
      </Typography>
      {option.subText && (
        <Box mt={option.noBold ? -1 : undefined}>
          <Typography
            variant={option.noBold ? "caption" : "body2"}
            sx={{ textWrap: "wrap" }}
          >
            {option.subText}
          </Typography>
        </Box>
      )}
    </MenuItem>
  );
}

export interface SelectFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: string;
  required?: boolean;
  options: (SelectFormFieldOption | GroupedSelectFormFieldOptions | string)[];
}

export function SelectFormField({
  name,
  label,
  required,
  options,
  ...wrapperProps
}: SelectFormFieldProps) {
  const renderedOptions = useMemo(
    (): React.ReactNode => options.flatMap((o) => renderOption(o)),
    [options],
  );

  const thisFieldIsRequired = useTranslatedField(
    (t) => t.common.thisFieldIsRequired,
    " / ",
  );

  return (
    <FieldWrapper {...wrapperProps}>
      <Field
        name={name}
        validate={(v) =>
          required && v == null ? thisFieldIsRequired : undefined
        }
        render={({ input, meta }) => (
          <TextField
            label={label}
            select
            fullWidth
            required={required}
            inputProps={input}
            error={meta.touched && meta.error}
            helperText={meta.touched && meta.error}
          >
            {renderedOptions}
            {!options && <MenuItem></MenuItem>}
          </TextField>
        )}
      />
    </FieldWrapper>
  );
}
