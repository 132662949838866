import React from "react";
import { ApplicationFormSpec } from "../applicationFormSpec";
import { ConvertToMui } from "../../Common/ConvertToMUI";
import { SelectFormField, TextFormField } from "../../Common/Fields";
import { FormSpy } from "react-final-form";
import { CFIFormResult } from "../cfiFormResult";
import { Grid } from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import {
  useTranslatedSelectOptions,
  useTranslatedField,
  useTranslations,
} from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { DeepPartial } from "../../Common/common";
import { SelectFormFieldOption } from "../../Common/Fields/SelectFormField";
import { useFieldValue } from "../../Common/useFieldValue";

export interface SelectNunavikOrgProps {
  getPrompt: (t: ApplicationFormSpec) => string | undefined;
  getOptions: (t: ApplicationFormSpec) => string[];
  showCustomCommunityOrgs?: boolean;
  alwaysShowCustom?: boolean;
}

export const COMMUNITY_ORGS = "Community Organizations";
export const OTHER_ORG = "Other";

export function SelectOrg({
  getPrompt,
  getOptions,
  showCustomCommunityOrgs,
  alwaysShowCustom,
}: SelectNunavikOrgProps) {
  const { t, iu } = useTranslations("applicant");
  const { t: tCommon, iu: iuCommon } = useTranslations("common");

  const otherLabel = useTranslatedField((t) => t.common.other, " / ");
  const communityOrganizationsLabel = useTranslatedField(
    (t) => t.applicant.communityOrganizations,
    " / ",
  );

  const applicantOrganization = useFieldValue<string | undefined>(
    "applicant.organization",
  );

  const options = useTranslatedSelectOptions(getOptions);

  const showCustom =
    alwaysShowCustom ||
    applicantOrganization === OTHER_ORG ||
    (showCustomCommunityOrgs && applicantOrganization == COMMUNITY_ORGS);

  return (
    <>
      <ConvertToMui getField={getPrompt} />

      <Grid container spacing={2}>
        <Grid item xs={12} md={showCustom ? 6 : 12}>
          <SelectFormField
            name="applicant.organization"
            label={appendWithoutHtml(
              t,
              iu,
              (t) => t.isOrganizationInNunavik.label,
              " / ",
            )}
            options={[
              ...options,
              ...(showCustomCommunityOrgs
                ? [
                    {
                      value: COMMUNITY_ORGS,
                      text: communityOrganizationsLabel,
                    },
                  ]
                : []),
              {
                value: OTHER_ORG,
                text: otherLabel,
              } satisfies SelectFormFieldOption,
            ]}
            icon={<BusinessIcon />}
          />
        </Grid>
        {showCustom && (
          <Grid item xs={12} md={6}>
            <TextFormField
              required
              name="applicant.customOrganization"
              label={appendWithoutHtml(
                tCommon,
                iuCommon,
                (t) => t.pleaseSpecify,
                " / ",
              )}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
}
