import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import {
  Box,
  Card,
  CardContent,
  Container,
  Paper,
  Skeleton,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { SelectedLanguageProvider } from "./SelectedLanguageProvider";
import { useBreakpoints } from "./Common/useBreakpoints";
import { TranslationProvider } from "./ApplicationForm/TranslationProvider";
import { useAsyncData } from "@torqit/crew-2ls";
import { translationApi } from "./Common/API";
import { ServiceOptionsProvider } from "./ApplicationForm/ServiceOptionsProvider";

export interface LayoutProps {}

export function Layout({}: LayoutProps) {
  const { data: translations, isLoading } = useAsyncData(
    () => translationApi.get(),
    [],
  );

  const [title, setTitle] = useState("Child First Initiative");

  const [frenchEnabled, setFrenchEnabled] = useState(
    navigator.language.startsWith("fr"),
  );

  const { md } = useBreakpoints();

  return (
    <Box bgcolor="secondary.main" minHeight="100vh">
      <Paper elevation={6}>
        <Stack
          py={2}
          px={5}
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          gap={2}
        >
          <Stack flex={1} direction="row" alignItems="center" gap={5}>
            <Box flex={1} maxWidth={300} sx={{ "& img": { maxWidth: "100%" } }}>
              <img src="/nrbhssSquareLogo.jpg" alt="NRBHSS Logo" />
            </Box>
            <Box
              flex={1.08}
              maxWidth={325}
              sx={{ "& img": { maxWidth: "100%" } }}
            >
              <img src="/cfiLogo.png" alt="CFI Logo" />
            </Box>
          </Stack>

          <Stack direction="row" alignItems="center">
            <Typography fontWeight="bold">EN</Typography>
            <Switch
              checked={frenchEnabled}
              onChange={(e) => setFrenchEnabled(e.target.checked)}
            />
            <Typography fontWeight="bold">FR</Typography>
          </Stack>
        </Stack>
      </Paper>
      <Box pt={2}>
        <Container maxWidth="xl">
          <SelectedLanguageProvider language={frenchEnabled ? "fr" : "en"}>
            <TranslationProvider {...translations}>
              {isLoading && (
                <Card>
                  <CardContent>
                    <Skeleton height={40} />
                    <Box pl={5}>
                      <Skeleton height={32} />
                      <Stack direction="row" gap={3}>
                        <Skeleton height={32} width={100} />
                        <Skeleton height={32} width={100} />
                      </Stack>
                    </Box>
                  </CardContent>
                </Card>
              )}
              {!isLoading && (
                <ServiceOptionsProvider>
                  <Outlet />
                </ServiceOptionsProvider>
              )}
            </TranslationProvider>
          </SelectedLanguageProvider>
        </Container>
      </Box>
    </Box>
  );
}
