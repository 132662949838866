import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Box,
} from "@mui/material";
import React from "react";
import { Field, FieldRenderProps } from "react-final-form";
import { FieldWrapper, FieldWrapperProps } from "./FieldWrapper";
import { useTranslatedField } from "../../ApplicationForm/TranslationProvider";

const CheckboxWrapper = (
  props: FieldRenderProps<string | number, HTMLElement, string | number> & {
    disabled?: boolean;
  },
) => {
  const {
    input: { checked, value, name, onChange, ...restInput },
  } = props;

  return (
    <Checkbox
      name={name}
      inputProps={restInput}
      onChange={onChange}
      checked={checked}
      value={value}
      disabled={props.disabled}
    />
  );
};

export interface CheckboxFormFieldProps
  extends Omit<FieldWrapperProps, "children"> {
  name: string;
  label?: string;
  mustBeTrue?: boolean;
  disabled?: boolean;
  helperText?: string;
}

export const CheckboxFormField: React.FC<CheckboxFormFieldProps> = ({
  name,
  label,
  mustBeTrue,
  disabled,
  helperText,
  ...wrapperProps
}) => {
  const thisMustBeChecked = useTranslatedField(
    (t) => t.common.thisMustBeChecked,
    " / ",
  );

  return (
    <FieldWrapper {...wrapperProps}>
      <Field
        name={name}
        type="checkbox"
        validate={(v) =>
          mustBeTrue && v !== true ? thisMustBeChecked : undefined
        }
        render={(props) => (
          <>
            <FormControlLabel
              control={
                <FormGroup>
                  <CheckboxWrapper {...props} disabled={disabled} />
                </FormGroup>
              }
              label={label}
              disabled={disabled}
            />
            {props.meta.touched && props.meta.error && (
              <Box p={1}>
                <FormHelperText disabled={disabled} error>
                  {props.meta.error}
                </FormHelperText>
              </Box>
            )}
            {helperText && !(props.meta.touched && props.meta.error) && (
              <Box marginTop={-1}>
                <FormHelperText disabled={disabled}>
                  {helperText}
                </FormHelperText>
              </Box>
            )}
          </>
        )}
      />
    </FieldWrapper>
  );
};
