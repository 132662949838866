import { sendGet } from "@torqit/crew-2ls";
import {
  ApplicantFormSpec,
  ApplicationFormSpec,
} from "../../ApplicationForm/applicationFormSpec";
import { DeepPartial } from "../common";
import { ApplicationType } from "../../ApplicationForm/Elimination/SelectApplicationType";

export interface ApplicationFormTranslations {
  en: ApplicationFormSpec;
  fr: ApplicationFormSpec;
  iu: ApplicationFormSpec;
}

interface TranslationSet {
  en_CA: string;
  fr_CA: string;
  iu: string;
}

export interface ServiceOption {
  text: TranslationSet;
  value: string;
}

export interface ServiceCategory {
  group: TranslationSet;
  applicationTypes: ApplicationType[];
  options: ServiceOption[];
}

export const translationApi = {
  get: () => sendGet<ApplicationFormTranslations>("/api/translations"),
  getServices: () => sendGet<ServiceCategory[]>("/api/translations/services"),
};
