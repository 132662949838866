import React from "react";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { RadioFormField, TextFormField } from "../../Common/Fields";
import PersonIcon from "@mui/icons-material/Person";
import TelephoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import {
  useTranslatedSelectOptions,
  useTranslations,
} from "../TranslationProvider";
import { appendWithoutHtml } from "../../Common/appendInuktitut";
import { AddressFields } from "../../Common/AddressFields";

export const contactPreferences = ["email", "telephone", "either"] as const;
export type ContactPreference = (typeof contactPreferences)[number];

export interface ApplicantInfoProps {}

export function ApplicantInfo({}: ApplicantInfoProps) {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up("sm"));

  const { t, iu } = useTranslations("applicant");

  const provincesAndTerritories = useTranslatedSelectOptions(
    (t) => t.applicant.applicantInfo.provincesAndTerritories,
  );

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.firstName"
          allowedContent="alpha"
          required
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.firstName,
            " / ",
          )}
          icon={<PersonIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.lastName"
          allowedContent="alpha"
          required
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.lastName,
            " / ",
          )}
          showSpace={!sm}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <TextFormField
          name="applicant.title"
          allowedContent="alpha"
          label={appendWithoutHtml(t, iu, (t) => t.applicantInfo.title, " / ")}
          showSpace={!sm}
        />
      </Grid>
      <AddressFields namespace="applicant" />
      <Grid item xs={12} sm={6}>
        <TextFormField
          name="applicant.telephone"
          allowedContent="numeric"
          maxLength={10}
          label={appendWithoutHtml(
            t,
            iu,
            (t) => t.applicantInfo.telephone,
            " / ",
          )}
          required
          icon={<TelephoneIcon />}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFormField
          name="applicant.email"
          label={appendWithoutHtml(t, iu, (t) => t.applicantInfo.email, " / ")}
          required
          icon={<EmailIcon />}
        />
      </Grid>
      <Grid item xs={12} mt={1}>
        <RadioFormField
          required
          name="applicant.preferredContact"
          label={(t) => t.applicant.applicantInfo.contactPreference}
          options={contactPreferences.map((c) => ({
            value: c,
            text: (t) => t.applicant.applicantInfo.contactPreferenceOptions[c],
          }))}
          showSpace
        />
      </Grid>
    </Grid>
  );
}
